<script setup lang="ts">
  import { ComponentCommonTypes } from '../../utils/commonTypes.ts';
  import { InputWidthVariant } from '../InputWrapper';
  import InputWrapper from '../InputWrapper/InputWrapper.vue';
  import {
    checkboxElementClass,
    checkboxLabelClass,
    checkboxWrapperClass,
  } from './Checkbox.css.ts';

  /**
   * This component is not fully developed and has to be used as controlled component
   */
  type CheckboxProps = ComponentCommonTypes & {
    value?: string;
    label?: string;
    name: string;
    disabled?: boolean;
    modelValue: boolean;
  };

  defineProps<CheckboxProps>();

  const emits = defineEmits(['update:modelValue']);

  const onChange = (e: Event) => {
    emits('update:modelValue', (e.target as HTMLInputElement).checked);
  };
</script>

<template>
  <InputWrapper :id="id" :label="label" :width="InputWidthVariant.FULL">
    <div :class="checkboxWrapperClass">
      <input
        :data-test-id="testId"
        :class="checkboxElementClass"
        type="checkbox"
        :checked="modelValue"
        :value="modelValue"
        @input="onChange"
        :name="name"
        :id="name"
        :disabled="disabled"
      />
      <slot name="value">
        <label :class="checkboxLabelClass" v-if="value" :for="name">{{
          value
        }}</label>
      </slot>
    </div>
  </InputWrapper>
</template>
