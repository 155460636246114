import { Plugin } from 'vue';
import axios, { AxiosInstance } from 'axios';
import { HTTP_TOKEN } from './config.ts';
import { setGlobalOptions } from 'vue-request';

type GlobalOptions = Parameters<typeof setGlobalOptions>[0];
const DEFAULT_BASE_URL = '/';
export type HttpPluginOptions = {
  baseURL?: string;
  globalRequestOptions?: GlobalOptions;
};

export const HttpPlugin: Plugin<HttpPluginOptions> = (
  app,
  { baseURL = DEFAULT_BASE_URL, globalRequestOptions } = {},
) => {
  const http = axios.create({ baseURL, withCredentials: true });
  app.provide<AxiosInstance>(HTTP_TOKEN, http);
  globalRequestOptions && setGlobalOptions(globalRequestOptions);
};
