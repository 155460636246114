<script setup lang="ts">
  import {
    chipClass,
    chipVariantClass,
    fixedWidthChipClass,
    squaredChipClass,
  } from './Chip.css';
  import { computed } from 'vue';
  import { ComponentCommonTypes } from '../utils/commonTypes.ts';
  import {
    clickableClass,
    textEllipsisClass,
    textUppercaseClass,
  } from '@tundr/theme';
  import { ChipVariant } from './Chip.types.ts';

  export type DividerProps = ComponentCommonTypes & {
    textEllipsis?: boolean;
    squared?: boolean;
    disableUppercase?: boolean;
    fixedWidth?: boolean;
    clickable?: boolean;
    variant?: ChipVariant;
  };

  defineEmits(['click']);

  const props = withDefaults(defineProps<DividerProps>(), {
    textEllipsis: true,
    disableUppercase: true,
    fixedWidth: false,
    clickable: false,
  });

  const chipClasses = computed(() => [
    chipClass,
    chipVariantClass[props.variant || ChipVariant.LIGHT],
    {
      [textUppercaseClass]: !props.disableUppercase,
      [squaredChipClass]: props.squared,
      [clickableClass]: props.clickable,
      [fixedWidthChipClass]: props.fixedWidth,
    },
  ]);

  const chipTextClasses = computed(() => [
    {
      [textEllipsisClass]: props.textEllipsis,
    },
  ]);
</script>

<template>
  <div :title="title" :id="id" :data-test-id="testId" :class="chipClasses">
    <span :class="chipTextClasses"><slot /></span>
  </div>
</template>
