import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
import { ButtonProps } from '@tundr/ui-kit';

export interface NotificationMessage {
  key: string;
  title: string;
  message: string;
  icon?: UNICONS_ICONS;
  dismissable?: boolean;
  variant?: NotificationVariant;
  actions?: (ButtonProps & { onClick?: () => void })[];
}

export enum NotificationVariant {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}
