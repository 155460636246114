import { cardRoutesDictionary } from './card-routes.dictionary.ts';
import { RouteRecordRaw } from 'vue-router';

export const cardRoutesConfig: RouteRecordRaw = {
  ...cardRoutesDictionary.card.root,
  redirect: { name: cardRoutesDictionary.card.cardRoot.name },
  children: [
    {
      ...cardRoutesDictionary.card.cardList,
      component: () => import('../pages/Tabs/CardListPage/CardListPage.vue'),
    },
    {
      ...cardRoutesDictionary.card.cardOrderslist,
      component: () =>
        import('../pages/Tabs/CardOrdersListPage/CardOrdersListPage.vue'),
    },
    {
      ...cardRoutesDictionary.card.cardOrdersDetail,
      props: true,
      component: () =>
        import(
          '../pages/Tabs/CardOrdersListPage/CardOrderDetailPage/CardOrderDetailPage.vue'
        ),
    },
    {
      ...cardRoutesDictionary.card.cardRoot,
      component: () => import('../pages/OrderTabsRoot.vue'),
    },

    {
      ...cardRoutesDictionary.card.create,
      component: () => import('../pages/CreateOrder/CreateOrderStepper.vue'),
    },
  ],
};
