import { Plugin } from 'vue';
import { useRouter } from 'vue-router';
import { useBreadcrumbStore } from './store/breadcrumb.store';

export type BreadcrumbPluginOptions = {
  resetPath: string;
};

export const BreadcrumbPlugin: Plugin<BreadcrumbPluginOptions> = (
  app,
  { resetPath },
) => {
  const router = app.runWithContext(() => useRouter());
  if (!router) {
    throw new Error(
      'Breadcrumb plugin requires vue router plugin to be already provided',
    );
  }
  router.beforeEach((to, from) => {
    const breadcrumbStore = useBreadcrumbStore();
    if (from.path.includes(resetPath)) {
      breadcrumbStore.reset();
    }
  });
};
