<script setup lang="ts">
  import { DividerDirection, DividerVariant } from './Divider.types';
  import { dividerColorClass, dividerDirectionClass } from './Divider.css';
  import { computed } from 'vue';
  import { ComponentCommonTypes } from '../utils/commonTypes.ts';

  export type DividerProps = ComponentCommonTypes & {
    variant?: DividerVariant;
    direction?: DividerDirection;
  };

  const props = withDefaults(defineProps<DividerProps>(), {
    variant: DividerVariant.LIGHT,
    direction: DividerDirection.HORIZONTAL,
  });

  const dividerClasses = computed(() => {
    return `${dividerDirectionClass[props.direction]} ${
      dividerColorClass[props.variant]
    }`;
  });
</script>

<template>
  <div :class="dividerClasses" />
</template>
