import { runInsideAppContext } from '@tundr/app-context';
import {
  useHttpRequestHeaders,
  useHttpResponseErrorInterceptor,
} from '@tundr/http';
import { HTTP_COMPANY_ID_HEADER_KEY } from './config.ts';
import { Plugin } from 'vue';
import { useAuthStore } from './store';
import { onInterceptUnauthorized } from './error-interceptor.ts';

export type PluginOptions = {
  configureCompanyIdInHeaders?: boolean;
};

export const AuthPlugin: Plugin = (app, options?: PluginOptions) => {
  const authStore = useAuthStore();

  // Install the error interceptor
  runInsideAppContext(() =>
    useHttpResponseErrorInterceptor(onInterceptUnauthorized),
  );

  if (options?.configureCompanyIdInHeaders) {
    authStore.$subscribe(
      (_, state) => {
        const { setHeaders, deleteHeader } = runInsideAppContext(
          useHttpRequestHeaders,
        );
        state.selectedCompany
          ? setHeaders({
              [HTTP_COMPANY_ID_HEADER_KEY]: state.selectedCompany.id,
            })
          : deleteHeader(HTTP_COMPANY_ID_HEADER_KEY);
      },
      { detached: true, immediate: true },
    );
  }
};
