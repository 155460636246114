<script setup lang="ts">
  import { useLocale } from '@tundr/i18n';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { FontIcon, TundrLogo, TundrLogoVariant } from '@tundr/ui-kit';
  import { useWindowSize } from '@vueuse/core';
  import { computed, inject, watchEffect } from 'vue';
  import { useSidebarStore } from '../../store/sidebar.store';
  import SidebarItem from '../SidebarItem/SidebarItem.vue';
  import {
    appVersionClass,
    closedSidebarClass,
    collapseButtonClass,
    collapseButtonCollapsedClass,
    collapseSidebarHeaderClass,
    collapseWrapperCollapsedClass,
    navigationWrapperClass,
    navigationWrapperCollapsedClass,
    openSidebarClass,
    sidebarFooterClass,
    sidebarHeaderClass,
    sidebarLogoClass,
    sidebarWrapperClass,
  } from './Sidebar.css';
  import { SidebarItemType, SidebarProps } from './Sidebar.types';

  const appVersion = inject('appVersion');

  const { t } = useLocale('common');

  const props = defineProps<SidebarProps>();

  const sidebarStore = useSidebarStore();
  const collapsed = computed(() => sidebarStore.collapsed);

  const { width: windowWidth } = useWindowSize();

  watchEffect(() => {
    if (windowWidth.value < 1024) {
      sidebarStore.setCollapsed(true);
    }
  });

  const sidebarClasses = computed(() => [
    sidebarWrapperClass,
    collapsed.value ? closedSidebarClass : openSidebarClass,
  ]);

  const navigationWrapperClasses = computed(() => [
    navigationWrapperClass,
    { [navigationWrapperCollapsedClass]: collapsed.value },
  ]);

  const collapseIconClasses = computed(() => [
    collapseButtonClass,
    { [collapseButtonCollapsedClass]: collapsed.value },
  ]);

  const sidebarFooterClasses = computed(() => [
    sidebarFooterClass,
    { [collapseWrapperCollapsedClass]: collapsed.value },
  ]);

  const sidebarHeaderClasses = computed(() => [
    sidebarHeaderClass,
    { [collapseSidebarHeaderClass]: collapsed.value },
  ]);

  const menuItemsFiltered = computed(() => {
    return props.menuItems.filter((item) => {
      if (item.type === SidebarItemType.MENU_ITEM) {
        return !item.isHidden;
      }
      return true;
    });
  });
</script>

<template>
  <div :class="sidebarClasses">
    <div :class="sidebarHeaderClasses">
      <slot name="header" v-bind="{ collapsed }" />
    </div>
    <div :class="navigationWrapperClasses">
      <SidebarItem
        v-for="(item, index) in menuItemsFiltered"
        :key="index"
        v-bind="item"
      />
    </div>
    <slot name="footer" />
    <div :class="sidebarFooterClasses">
      <div :class="sidebarLogoClass">
        <span v-if="!collapsed">{{ t('common:words.powered_by') }}</span>
        <TundrLogo
          :variant="
            collapsed ? TundrLogoVariant.SMALL_DARK : TundrLogoVariant.DARK
          "
        />
      </div>
      <FontIcon
        :test-id="'collapse-button'"
        :class="collapseIconClasses"
        :icon-name="
          collapsed ? UNICONS_ICONS.ARROWS_RESIZE_H : UNICONS_ICONS.ARROWS_MERGE
        "
        @click="sidebarStore.toggleCollapse"
      />
    </div>
    <div v-if="appVersion" :class="appVersionClass">v. {{ appVersion }}</div>
  </div>
</template>
