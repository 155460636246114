import { defineStore } from 'pinia';
import { StorageSerializers, useStorage } from '@vueuse/core';
import { computed } from 'vue';

export const SIDEBAR_STORE_NAME = 'sidebar';

export const useSidebarStore = defineStore(SIDEBAR_STORE_NAME, () => {
  const sidebarStore = useStorage<{ collapsed: boolean }>(
    SIDEBAR_STORE_NAME,
    { collapsed: false },
    localStorage,
    { serializer: StorageSerializers.object },
  );

  const toggleCollapse = () => {
    sidebarStore.value.collapsed = !sidebarStore.value.collapsed;
  };

  const setCollapsed = (collapsed: boolean) => {
    sidebarStore.value.collapsed = collapsed;
  };

  const collapsed = computed(() => sidebarStore.value.collapsed);

  return {
    toggleCollapse,
    setCollapsed,
    collapsed,
  };
});
