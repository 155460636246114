<script setup lang="ts">
  import { ref } from 'vue';
  import {
    ModalSkeleton,
    ModalTitle,
    ModalActions,
    ModalContent,
    useModal,
  } from '@tundr/modal';
  import { useLogout } from '../../../../../shared/composables/use-logout.ts';
  import TermsModal from '../TermsModal/TermsModal.vue';
  import { Link, Checkbox, LinkTarget } from '@tundr/ui-kit';
  import { LinkVariant } from '@tundr/theme';
  import { privacyLinkClass } from './PrivacyModalClass.css';
  import { useLocale } from '@tundr/i18n';
  import { ENV_VARS } from '../../../../../core/config/env';

  const { t } = useLocale('serviceAgreements');
  const { openModal, closeModal } = useModal();
  const { onLogout } = useLogout();

  const accepted = ref<boolean>(false);

  const onConfirm = () => {
    openModal({
      component: TermsModal,
    });
  };

  const onCancel = () => {
    closeModal();

    // fix ugly ui behaviour
    setTimeout(() => {
      onLogout();
    }, 150);
  };

  const privacyDocumentUrl = ENV_VARS.serviceAgreements.privacy;
</script>

<template>
  <ModalSkeleton>
    <template #title>
      <ModalTitle>{{ t('privacy.modal_title') }}</ModalTitle>
    </template>
    <ModalContent>
      <Checkbox
        v-model="accepted"
        name="privacy"
        :value="t('privacy.checkbox_label')"
      />
      <br />
      <Link
        v-if="privacyDocumentUrl"
        :title="t('privacy.link_text')"
        :href="privacyDocumentUrl"
        :variant="LinkVariant.external"
        :target="LinkTarget.BLANK"
        :class="privacyLinkClass"
      >
        {{ t('privacy.link_text') }}
      </Link>
    </ModalContent>
    <template #actions>
      <ModalActions
        :confirmLabel="t('common:actions.confirm')"
        :disableConfirm="!accepted"
        @confirm="onConfirm"
        @cancel="onCancel"
        :cancelLabel="t('common:actions.cancel')"
      />
    </template>
  </ModalSkeleton>
</template>
