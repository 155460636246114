import { HttpRequestFn, UseHttpOptions, useHttp } from '@tundr/http';
import {
  BankProviderLoginPayload,
  BankProviderLoginResponse,
} from './bank-provider-api.types.ts';

const BASE_API_URL = 'swan';

const bankProviderLogin: HttpRequestFn<
  BankProviderLoginResponse,
  BankProviderLoginPayload
> = (http) => (args) => {
  return http.post(`${BASE_API_URL}/login`, args);
};

export const useBankProviderLogin = (
  args: UseHttpOptions<
    BankProviderLoginResponse,
    BankProviderLoginPayload
  > = {},
) =>
  useHttp(bankProviderLogin, {
    manual: true,
    ...args,
  });
