<script lang="ts" setup>
  import { computed } from 'vue';
  import { useNotification } from '../../composables/use-notification';
  import {
    notificationWrapperClass,
    notificationBackgroundByVariantClass,
    notificationLeftSideClass,
    notificationContentClass,
    notificationIconClass,
    dismissIconClass,
    notificationDismissableClass,
    statusIconWrapperClass,
    statusIconBackgroundClass,
  } from './Notification.css';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { NotificationVariant } from '../../store/notification.types';
  import { FontIcon, ButtonSizes, Button } from '@tundr/ui-kit';
  import { ButtonVariant, rowCenterClass } from '@tundr/theme';

  const notification = useNotification();

  const lastNotification = computed(() => {
    return notification.getLastNotification.value;
  });

  const notificationWrapperClasses = computed(() => {
    return [
      notificationWrapperClass,
      {
        [notificationDismissableClass]: lastNotification.value?.dismissable,
      },
      notificationBackgroundByVariantClass[
        lastNotification.value?.variant || NotificationVariant.WARNING
      ],
    ];
  });

  const statusIconClasses = computed(() => [
    statusIconWrapperClass,
    statusIconBackgroundClass[
      lastNotification.value?.variant || NotificationVariant.WARNING
    ],
  ]);

  const iconByVariant = computed(() => {
    const iconsMap = {
      [NotificationVariant.ERROR]: UNICONS_ICONS.TIMES_CIRCLE,
      [NotificationVariant.WARNING]: UNICONS_ICONS.EXCLAMATION_TRIANGLE,
      [NotificationVariant.SUCCESS]: UNICONS_ICONS.CHECK_CIRCLE,
      [NotificationVariant.INFO]: UNICONS_ICONS.INFO_CIRCLE,
    };
    return iconsMap[
      lastNotification.value?.variant || NotificationVariant.INFO
    ];
  });

  const onDismiss = () => {
    notification.removeNotification(lastNotification.value!.key);
  };
</script>

<template>
  <Transition :appear="true" name="slide-down">
    <div v-if="lastNotification" :class="notificationWrapperClasses">
      <FontIcon
        @click="onDismiss"
        clickable
        :class="dismissIconClass"
        :iconName="UNICONS_ICONS.MULTIPLY"
        v-if="lastNotification.dismissable"
      />
      <div :class="notificationLeftSideClass">
        <div :class="statusIconClasses">
          <FontIcon :class="notificationIconClass" :iconName="iconByVariant" />
        </div>

        <div :class="notificationContentClass">
          <strong>{{ lastNotification.title }}</strong>
          <span>{{ lastNotification.message }}</span>
        </div>
      </div>
      <div v-if="lastNotification.actions" :class="rowCenterClass">
        <Button
          v-for="button in lastNotification.actions"
          :key="button.label"
          :size="button.size || ButtonSizes.SMALL"
          :variant="button.variant || ButtonVariant.transparentOutlined"
          v-bind="button"
        />
      </div>
    </div>
  </Transition>
</template>

<style scoped>
  .slide-down-enter-active,
  .slide-down-leave-active {
    transition: all 0.4s ease;
  }
  .slide-down-enter-from,
  .slide-down-leave-to {
    transform: translateY(-20px);
  }
</style>
