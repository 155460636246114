<script lang="ts" setup>
  import { toRef, toRefs } from 'vue';
  import TextInput from './TextInput.vue';
  import { useField } from 'vee-validate';
  import { InputWidthVariant } from '../InputWrapper/';
  import { TextInputProps } from './TextInput.types.ts';

  export type TextInputFieldProps = TextInputProps & {
    rules?: (value: string) => string | boolean;
  };

  const props = withDefaults(defineProps<TextInputFieldProps>(), {
    value: undefined,
    rules: undefined,
    type: 'text',
    width: InputWidthVariant.FULL,
  });

  const {
    name: inputName,
    label,
    rules,
    ...rest
  } = toRefs<TextInputFieldProps>(props);
  // workaround to keep reactivity on rest value from toRefs
  const inputProps = toRef(rest);

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
  } = useField<string>(inputName.value, rules?.value, {
    label: label?.value,
  });
</script>

<template>
  <TextInput
    v-bind="inputProps"
    :error="errorMessage as string"
    :label="label"
    :meta="meta"
    :loading="loading"
    :modelValue="inputValue as string"
    :name="name"
    :placeholder="placeholder"
    @blur="handleBlur"
    @update:modelValue="handleChange"
  >
    <template #iconRight>
      <slot name="iconRight"></slot>
    </template>
    <template #inputLabel>
      <slot name="inputLabel"></slot>
    </template>
    <template #inputError>
      <slot name="inputError"></slot>
    </template>
    <template #iconLeft>
      <slot name="iconLeft"></slot>
    </template>
  </TextInput>
</template>
