import { createI18n, I18n, I18nOptions } from 'vue-i18n';
import { Plugin } from 'vue';
import { DEFAULT_LOCALE, I18N_AVAILABLE_LOCALES } from './config/locales.ts';
import { generateNumberFormatsConfig } from './config/number-formats.config.ts';
import { generateDatetimeFormatsConfig } from './config/datetime-formats.config.ts';

type I18NPluginConfig = I18nOptions & {
  debugMode?: boolean;
};

export type I18nPluginOptions = {
  i18n: I18n;
};

export const generateI18nInstance = (configOptions: I18NPluginConfig) =>
  createI18n({
    locale: DEFAULT_LOCALE,
    fallbackLocale: DEFAULT_LOCALE,
    availableLocales: I18N_AVAILABLE_LOCALES,
    legacy: false,
    ...configOptions,
    numberFormats: generateNumberFormatsConfig(I18N_AVAILABLE_LOCALES),
    datetimeFormats: generateDatetimeFormatsConfig(I18N_AVAILABLE_LOCALES),
  });

export const I18nPlugin: Plugin<I18nPluginOptions> = (app, { i18n }) => {
  app.use(i18n);
};
