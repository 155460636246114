<script setup lang="ts">
  import { iconBaseClass, boxedIconClass } from './FontIcon.css.ts';
  import { clickableClass, disabledClass } from '@tundr/theme';
  import { computed } from 'vue';
  import { FontIconProps } from './FontIcon.types';

  const props = withDefaults(defineProps<FontIconProps>(), {
    clickable: false,
    disabled: false,
    boxed: false,
    rotation: 0,
  });

  const iconClasses = computed(() => [
    'uit',
    `uit-${props.iconName}`,
    iconBaseClass,
    {
      [disabledClass]: props.disabled,
      [boxedIconClass]: props.boxed,
      [clickableClass]: !props.disabled && props.clickable,
    },
  ]);
</script>

<template>
  <i
    :id="id"
    :data-test-id="testId"
    :title="title"
    :class="iconClasses"
    :role="props.clickable ? 'button' : 'img'"
    :style="`transform: rotate(${String(rotation)}deg)`"
    v-bind="$attrs"
  />
</template>
