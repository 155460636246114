import { SidebarMenuItemProps } from '../SidebarMenuItem/SidebarMenuItem.types.ts';

export enum SidebarItemType {
  MENU_ITEM = 'menuItem',
  SECTION_HEADER = 'sectionHeader',
  DIVIDER = 'divider',
}

export type SidebarNavigationItem = SidebarMenuItemProps & {
  type: SidebarItemType.MENU_ITEM;
};

export type SidebarDividerItem = {
  type: SidebarItemType.DIVIDER;
};

export type SidebarSectionHeaderItem = {
  type: SidebarItemType.SECTION_HEADER;
  label: string;
};

export type SidebarItemElement =
  | SidebarNavigationItem
  | SidebarDividerItem
  | SidebarSectionHeaderItem;

export type SidebarProps = {
  menuItems: SidebarItemElement[];
};
