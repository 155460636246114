import { officeRoutesDictionary } from './office-routes.dictionary.ts';
import { RouteRecordRaw } from 'vue-router';

export const officesRoutesConfig: RouteRecordRaw = {
  ...officeRoutesDictionary.offices.root,
  redirect: { name: officeRoutesDictionary.offices.list.name },
  children: [
    {
      ...officeRoutesDictionary.offices.list,
      component: () => import('../pages/List/ListOfficePage.vue'),
    },
    {
      ...officeRoutesDictionary.offices.create,
      component: () => import('../pages/Create/CreateOfficePage.vue'),
    },
    {
      ...officeRoutesDictionary.offices.update,
      component: () => import('../pages/Edit/EditOfficePage.vue'),
      props: true,
    },
  ],
};
