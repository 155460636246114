<script setup lang="ts">
  import { isDateMatch, italianDateFormat, useLocale } from '@tundr/i18n';
  import { disabledClass } from '@tundr/theme';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import VueDatePicker, { DatePickerInstance } from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import { isValid, parse } from 'date-fns';
  import { it } from 'date-fns/locale';
  import { FieldMeta } from 'vee-validate';
  import { computed, onMounted, ref, useTemplateRef } from 'vue';
  import FontIcon from '../../FontIcon/FontIcon.vue';
  import { InputWidthVariant } from '../InputWrapper';
  import { inputWidthClass } from '../InputWrapper/InputWrapper.css.ts';
  import { TextInput } from '../TextInput';
  import './DatePicker-override.css.ts';
  import {
    calendarIconClass,
    customDatePickerMenuClass,
    datePickerBaseClass,
    datePickerInputClass,
  } from './DatePicker.css.ts';
  import { DatePickerProps } from './DatePicker.types.ts';

  const props = defineProps<
    DatePickerProps & { modelValue: Date | undefined; meta?: FieldMeta<Date> }
  >();

  const { currentLanguageDateFormat } = useLocale();

  const emit = defineEmits<{
    'update:modelValue': [Date | undefined];
    blur: [Event | undefined];
  }>();

  const datepicker = useTemplateRef<DatePickerInstance>('datepicker');
  const internalModelRef = ref(props.modelValue);

  const handleInternalModelChange = (date: Date) => {
    internalModelRef.value = date;
  };

  const handleInput = (date: string) => {
    const parsedDate = parse(date, currentLanguageDateFormat.value, new Date());
    if (
      isDateMatch(date, currentLanguageDateFormat.value) &&
      isValid(parsedDate)
    ) {
      datepicker.value!.selectDate();
      emit('update:modelValue', parsedDate);
    }
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  defineOptions({
    inheritAttrs: false,
  });

  const datePickerContainerClasses = computed<string[]>(() => [
    inputWidthClass[props.width || InputWidthVariant.AUTO],
  ]);

  const datePickerClasses = {
    [datePickerBaseClass]: true,
    [disabledClass]: props.disabled,
  };

  const onBlur = (ev?: Event) => {
    emit('blur', ev);
  };

  const onConfirmInput = () => {
    emit('update:modelValue', internalModelRef.value);
    datepicker.value!.closeMenu();
  };

  const onFocusOut = () => {
    emit('blur', undefined);
  };

  onMounted(() => {
    if (props.autofocus && datepicker.value) {
      datepicker.value.openMenu();
    }
  });
</script>

<template>
  <div :class="datePickerContainerClasses">
    <VueDatePicker
      :classes="datePickerClasses"
      :id="id"
      ref="datepicker"
      :name="name"
      locale="it"
      autocomplete="off"
      :format-locale="it"
      :min-date="minDate"
      :max-date="maxDate"
      :format="italianDateFormat"
      :prevent-min-max-navigation="preventMinMaxNavigation"
      :start-date="startDate"
      :focus-start-date="focusStartDate"
      :enable-time-picker="false"
      :disabled="disabled || loading || readonly"
      :model-value="modelValue"
      :menu-class-name="customDatePickerMenuClass"
      :clearable="false"
      :timezone="timeZone"
      text-input
      auto-apply
      auto-position
      :autofocus="autofocus"
      @internal-model-change="handleInternalModelChange"
      @update:model-value="emit('update:modelValue', $event)"
      @blur="onBlur"
      v-bind="$attrs"
    >
      <template #dp-input="inputProps">
        <TextInput
          :class="[inputClass, datePickerInputClass]"
          :meta="meta"
          :test-id="testId"
          :disabled="disabled || loading"
          :loading="loading"
          :readonly="readonly"
          :width="width"
          :name="name"
          :autofocus="autofocus"
          :label="label"
          :placeholder="placeholder"
          :error="error"
          @onEnter="onConfirmInput"
          @onTab="onConfirmInput"
          @blur="onBlur"
          @focusout="onFocusOut"
          @update:model-value="handleInput"
          :model-value="inputProps.value"
          v-bind="inputProps"
        >
          <template #iconRight>
            <FontIcon
              :class="calendarIconClass"
              :icon-name="UNICONS_ICONS.CALENDAR_ALT"
            />
          </template>
        </TextInput>
      </template>
    </VueDatePicker>
  </div>
</template>
