import { customRef, Ref } from 'vue';

/**
 * Selector for focusable elements.
 */
const focusableElementsSelector =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

/**
 * Hook for creating a focus trap within a specified DOM element.
 * This trap ensures that the keyboard focus remains within the specified element.
 *
 * @param firstSelectionOnLastItem - Whether to select the first focusable element on the last focusable element.
 * @returns An object containing `trapRef`, a Vue ref that should be bound to the element to trap focus within.
 */
export const useFocusTrap = (firstSelectionOnLastItem = true) => {
  let focusableElements: NodeListOf<HTMLElement> = null!;
  let firstFocusable: HTMLElement | null = null;
  let lastFocusable: HTMLElement | null = null;

  /**
   * Custom Vue ref used to track and manage the focus trap element.
   */
  const trapRef: Ref<HTMLElement | null> = customRef((track, trigger) => {
    let trapEl: HTMLElement | null = null;
    return {
      get(): HTMLElement | null {
        track();
        return trapEl;
      },
      set(value: HTMLElement | null): void {
        trapEl = value;
        if (value) {
          initFocusTrap();
        } else {
          clearFocusTrap();
        }
        trigger();
      },
    };
  });

  /**
   * Handler for keyboard events. Used to manage focus within the trap.
   *
   * @param e - The keyboard event.
   */
  const keyHandler = (e: KeyboardEvent): void => {
    const isTabPressed: boolean = e.key === 'Tab';

    if (!isTabPressed) return;

    if (e.shiftKey) {
      if (document.activeElement === firstFocusable) {
        lastFocusable?.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusable) {
        firstFocusable?.focus();
        e.preventDefault();
      }
    }
  };

  /**
   * Initializes the focus trap by setting up event listeners and focusing the first element.
   */
  const initFocusTrap = (): void => {
    if (!trapRef.value) return;
    focusableElements = trapRef.value.querySelectorAll<HTMLElement>(
      focusableElementsSelector,
    );
    firstFocusable = focusableElements[0];
    lastFocusable = focusableElements[focusableElements.length - 1];
    document.addEventListener('keydown', keyHandler);
    if (firstSelectionOnLastItem) {
      lastFocusable?.focus();
    } else {
      firstFocusable?.focus();
    }
  };

  /**
   * Clears the focus trap by removing event listeners.
   */
  const clearFocusTrap = (): void => {
    document.removeEventListener('keydown', keyHandler);
  };

  return {
    trapRef,
    initFocusTrap,
    clearFocusTrap,
  };
};
