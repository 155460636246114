<script setup lang="ts">
  import { bradCrumbContainerClass } from './BreadCrumb.css';
  import BreadCrumbItem from './BreadCrumbItem/BreadCrumbItem.vue';
  import { useBreadcrumbStore } from '../../store/breadcrumb.store';
  import { computed } from 'vue';

  const breadcrumbStore = useBreadcrumbStore();

  const items = computed(() => {
    return breadcrumbStore.items;
  });
</script>

<template>
  <div :class="bradCrumbContainerClass" v-if="items && items.length">
    <BreadCrumbItem
      v-for="(item, index) in items"
      :key="item.title"
      v-bind="item"
      :isLast="items.length - 1 === index"
    />
  </div>
</template>
