import { computed } from 'vue';
import { StepperComponent, useStepperStore } from '../store/stepper.store';

export function useStepper(namespace: string) {
  const stepperStore = useStepperStore();

  const initializeSteps = (components: StepperComponent[]) => {
    stepperStore.initializeStepper(namespace, components);
  };

  const nextStep = () => {
    stepperStore.nextStep(namespace);
  };

  const prevStep = () => {
    stepperStore.previousStep(namespace);
  };

  const goToStep = (stepName: string) => {
    stepperStore.goToStep(namespace, stepName);
  };

  const setProps = (props: Record<string, unknown>) => {
    stepperStore.setStepperProps(namespace, props);
  };

  const getProps = computed(() => {
    return stepperStore.steppers[namespace].sharedProps;
  });

  const activeIndex = computed<number>(
    () => stepperStore.steppers[namespace]?.activeIndex,
  );

  const totalSteps = computed<number>(
    () => stepperStore.steppers[namespace]?.components.length,
  );

  const currentActiveComponent = computed(() => {
    return stepperStore.steppers[namespace]?.components[activeIndex.value]
      ?.component;
  });

  const canGoBack = computed<boolean>(() => activeIndex.value > 0);

  const canGoNext = computed<boolean>(
    () =>
      activeIndex.value <
      stepperStore.steppers[namespace]?.components.length - 1,
  );

  const getStepIndexByName = (stepName: string) => {
    return stepperStore.steppers[namespace]?.components.findIndex(
      (component) => component.name === stepName,
    );
  };

  const isActiveStep = (stepName: string) => {
    return (
      stepperStore.steppers[namespace]?.activeIndex ===
      getStepIndexByName(stepName)
    );
  };

  const destroyStepper = () => {
    stepperStore.destroyStepper(namespace);
  };

  return {
    initializeSteps,
    nextStep,
    prevStep,
    goToStep,
    activeIndex,
    currentActiveComponent,
    canGoBack,
    canGoNext,
    isActiveStep,
    totalSteps,
    destroyStepper,
    setProps,
    getProps,
  };
}
