<script lang="ts" setup>
  import { useLocale } from '@tundr/i18n';
  import { NotificationVariant, useNotification } from '@tundr/notification';
  import { RoutingView } from '@tundr/routing';
  import { Sidebar, SidebarItemElement, SidebarItemType } from '@tundr/sidebar';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { useToast } from '@tundr/toast';
  import { Divider, PageLayout } from '@tundr/ui-kit';
  import { computed, ref } from 'vue';
  import { useBankProviderLogin } from '../../core/api/bank-provider/bank-provider.api';
  import { useGetHrVerificationStatus } from '../../core/api/accounts-memberships/accounts-memberships.api';
  import { ENV_VARS } from '../../core/config/env';
  import { appRoutesDictionary } from '../../core/router/app-routes.dictionary.ts';
  import { observeWindow } from '../../core/utils/bank-provider/bank-provider-consent-manager';
  import SidebarCompanyRow from '../../shared/components/Company/SidebarCompanyRow/SidebarCompanyRow.vue';
  import UserMenu from '../../shared/components/UserMenu/UserMenu.vue';
  import { sidebarHeaderSectionClass } from './AppRouter.css';
  import { WelfarePlan, useAuthStore } from '@tundr/auth';

  const notification = useNotification();
  const { showSuccess, showError } = useToast();

  const authStore = useAuthStore();

  const isStandardPlan = computed(() => {
    return authStore.currentWelfarePlan === WelfarePlan.STANDARD;
  });

  const isCompletePlan = computed(() => {
    return authStore.currentWelfarePlan === WelfarePlan.COMPLETE;
  });

  const HR_VERIFICATION_NOTIFICATION_KEY = 'hr-verification';

  const timeoutInstance = ref<ReturnType<typeof setTimeout> | null>(null);

  const { t } = useLocale('common');

  const chatLoaded = ref(false);

  const openSupportChat = () => {
    const { HubSpotConversations } = window;
    if (!HubSpotConversations) {
      return;
    }

    const status: { loaded: boolean } = HubSpotConversations.widget.status();
    chatLoaded.value = status.loaded;

    if (!status.loaded) {
      HubSpotConversations.widget.load();
      return;
    }

    HubSpotConversations.widget.remove();
  };

  const { run: runBankProviderLogin } = useBankProviderLogin({
    onSuccess: async (res) => {
      const observeWindowConfigured = observeWindow(timeoutInstance);
      await observeWindowConfigured(res.data.loginUri);
      showSuccess({
        message: t('hr:hr_verification_status.success.title'),
        description: t('hr:hr_verification_status.success.description'),
      });
      setTimeout(() => {
        refreshGetHrStatus();
      }, 5000);
    },
    onError: () => {
      showError({
        message: t('hr:hr_verification_status.error.title'),
        description: t('hr:hr_verification_status.error.description'),
      });
    },
  });

  const { refresh: refreshGetHrStatus } = useGetHrVerificationStatus({
    onSuccess: (res) => {
      if (res.data?.requiresIdentityVerification) {
        notification.addNotification({
          key: HR_VERIFICATION_NOTIFICATION_KEY,
          title: t('hr:hr_verification_status.title'),
          message: t('hr:hr_verification_status.message'),
          variant: NotificationVariant.WARNING,
          dismissable: false,
          actions: [
            {
              label: t('hr:hr_verification_status.actions.confirm_permission'),
              onClick: () => {
                runBankProviderLogin({ redirectUri: window.location.href });
              },
            },
          ],
        });
      } else {
        notification.removeNotification(HR_VERIFICATION_NOTIFICATION_KEY);
      }
    },
  });

  const onGoToFAQs = () => {
    window.open(ENV_VARS.faqs, '_blank');
  };

  const menuItems = computed<SidebarItemElement[]>(() => [
    {
      type: SidebarItemType.SECTION_HEADER,
      label: t('pages.section_header'),
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('pages.employees'),
      to: appRoutesDictionary.employees.root,
      icon: UNICONS_ICONS.USERS_ALT,
      isFontIcon: true,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('pages.credits'),
      to: appRoutesDictionary.credits.root,
      icon: UNICONS_ICONS.COINS,
      isFontIcon: true,
      isHidden: !isStandardPlan.value,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('pages.flex-credits'),
      to: appRoutesDictionary.flexCredits.root,
      icon: UNICONS_ICONS.COINS,
      isFontIcon: true,
      isHidden: !isCompletePlan.value,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('pages.fringe-thresholds'),
      to: appRoutesDictionary.fringeThresholds.root,
      icon: UNICONS_ICONS.TOP_ARROW_TO_TOP,
      isFontIcon: true,
      isHidden: !isCompletePlan.value,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('pages.card'),
      to: appRoutesDictionary.card.root,
      icon: ICONS.CARD,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('pages.hr'),
      to: appRoutesDictionary.hr.root,
      icon: ICONS.GROUPS,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('pages.offices'),
      to: appRoutesDictionary.offices.root,
      icon: UNICONS_ICONS.LOCATION_POINT,
      isFontIcon: true,
    },
    {
      type: SidebarItemType.SECTION_HEADER,
      label: t('assistance.section_header'),
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('assistance.chat'),
      isFontIcon: true,
      icon: UNICONS_ICONS.COMMENT_QUESTION,
      onClick: openSupportChat,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('assistance.faq'),
      isFontIcon: true,
      icon: UNICONS_ICONS.QUESTION_CIRCLE,
      onClick: onGoToFAQs,
    },
  ]);
</script>

<template>
  <PageLayout>
    <template #sidebar>
      <Sidebar :menu-items="menuItems">
        <template #header="{ collapsed }">
          <div :class="sidebarHeaderSectionClass">
            <UserMenu :collapsed="collapsed" />
            <SidebarCompanyRow :collapsed="collapsed" />
            <Divider v-if="!collapsed" />
          </div>
        </template>
      </Sidebar>
    </template>

    <RoutingView />
  </PageLayout>
</template>
