<script setup lang="ts">
  import {
    ButtonVariant,
    disabledClass,
    shake,
    textEllipsisClass,
  } from '@tundr/theme';
  import { computed, ref, useAttrs, useSlots } from 'vue';
  import { LoaderSize } from '../Loader';
  import Loader from '../Loader/Loader.vue';
  import { Tooltip } from '../Tooltip';
  import {
    btnAlignment,
    btnIconLeft,
    btnIconRight,
    btnVariants,
    btnWidth,
    btnSize,
    buttonWithoutEllipsisClass,
  } from './Button.css';
  import {
    ButtonAlignmentVariant,
    ButtonProps,
    ButtonSizes,
    ButtonTypes,
    ButtonWidthVariant,
  } from './Button.types';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import Icon from '../Icon/Icon.vue';
  import FontIcon from '../FontIcon/FontIcon.vue';

  const props = withDefaults(defineProps<ButtonProps>(), {
    role: 'button',
    type: ButtonTypes.BUTTON,
    width: ButtonWidthVariant.AUTO,
    size: ButtonSizes.DEFAULT,
    textEllipsis: true,
    loading: false,
    disabled: false,
    variant: ButtonVariant.primary,
    textAlign: ButtonAlignmentVariant.CENTER,
  });
  const emits = defineEmits(['click']);

  const isSubmitting = ref<boolean>(false);

  const slots = useSlots();
  const attrs = useAttrs();

  defineOptions({
    inheritAttrs: false,
  });

  // we get the button text from default slot to use it in aria-label
  const buttonText = computed(() => {
    return slots?.default && (slots?.default()[0].children as string);
  });

  const baseClasses = computed(() => [
    attrs.class,
    btnVariants[props.variant],
    btnWidth[props.width],
    btnAlignment[props.textAlign],
    btnSize[props.size],
  ]);

  const conditionalClasses = computed(() => {
    return {
      [textEllipsisClass]: props.textEllipsis,
      [buttonWithoutEllipsisClass]: props.textEllipsis === false,
      [disabledClass]: props.disabled || props.loading,
      [shake]: isSubmitting.value,
    };
  });

  const buttonTextClass = computed(() => ({
    [textEllipsisClass]: props.textEllipsis,
  }));

  const handleClick = () => {
    if (
      props.type === ButtonTypes.SUBMIT &&
      (props.disabled || props.loading)
    ) {
      isSubmitting.value = true;
      setTimeout(() => {
        isSubmitting.value = false;
      }, 500);
    }
    if (!props.disabled && !props.loading) {
      emits('click');
    }
  };
</script>

<template>
  <template v-if="tooltipText">
    <Tooltip v-if="tooltipText" :label="tooltipText" :class="tooltipClass">
      <button
        :id="id"
        :data-test-id="testId"
        :title="title"
        :role="role"
        :type="type"
        :class="[baseClasses, conditionalClasses]"
        :aria-label="buttonText"
        @click="handleClick"
        :isDisabled="disabled"
      >
        <slot name="iconLeft">
          <div v-if="iconName" :class="btnIconLeft">
            <Icon
              v-if="iconName && !isFontIcon"
              :iconName="iconName as ICONS"
            />
            <FontIcon
              v-if="iconName && isFontIcon"
              :iconName="iconName as UNICONS_ICONS"
            />
          </div>
        </slot>
        <div v-if="loading">
          <Loader :size="LoaderSize.small" />
        </div>
        <span :class="buttonTextClass" v-else
          ><slot>{{ label }}</slot></span
        >
        <div v-if="slots['iconRight']" :class="btnIconRight">
          <slot name="iconRight" />
        </div>
      </button>
    </Tooltip>
  </template>
  <template v-else>
    <button
      :id="id"
      :data-test-id="testId"
      :title="title"
      :role="role"
      :type="type"
      :class="[baseClasses, conditionalClasses]"
      :aria-label="buttonText"
      @click="handleClick"
      :isDisabled="disabled"
    >
      <slot name="iconLeft">
        <div v-if="iconName" :class="btnIconLeft">
          <Icon v-if="iconName && !isFontIcon" :iconName="iconName as ICONS" />
          <FontIcon
            v-if="iconName && isFontIcon"
            :iconName="iconName as UNICONS_ICONS"
          />
        </div>
      </slot>
      <div v-if="loading">
        <Loader :size="LoaderSize.small" />
      </div>
      <span :class="buttonTextClass" v-else
        ><slot>{{ label }}</slot></span
      >
      <div v-if="slots['iconRight']" :class="btnIconRight">
        <slot name="iconRight" />
      </div>
    </button>
  </template>
</template>
