import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const flexCreditsRoutesDictionary = moduleRouteDictionaryFactory(
  'flexCredits',
  'flexCredits',
  {
    list: 'list',
    assign: 'assign',
  },
);
