<script lang="ts" setup>
  import { watchEffect } from 'vue';
  import {
    CurrencyDisplay,
    CurrencyInputOptions,
    useCurrencyInput,
    ValueScaling,
  } from 'vue-currency-input';
  import { InputWidthVariant } from '../InputWrapper/';
  import { TextInputProps } from '../TextInput';
  import TextInput from '../TextInput/TextInput.vue';
  import { eurIconClass } from './CurrencyInput.css';
  import { BASE_CURRENCY_DIVISION } from '@tundr/i18n';

  type CurrencyInputProps = Omit<TextInputProps, 'type'> & {
    min?: number;
    max?: number;
  };

  const props = withDefaults(defineProps<CurrencyInputProps>(), {
    width: InputWidthVariant.MIN,
    modelValue: 0,
  });

  const emit = defineEmits(['update:modelValue', 'blur', 'change']);

  const setLimit = (value: number | undefined) => {
    return value !== undefined ? value : undefined;
  };

  const defaultOptions: CurrencyInputOptions = {
    currency: 'EUR',
    precision: 2,
    hideCurrencySymbolOnFocus: true,
    autoDecimalDigits: false,
    currencyDisplay: CurrencyDisplay.hidden,
    useGrouping: false,
    valueScaling: ValueScaling.precision,
    valueRange: {
      min: setLimit(props.min),
      max: setLimit(props.max),
    },
  };

  const { inputRef, formattedValue, setValue, setOptions, numberValue } =
    useCurrencyInput(defaultOptions);

  watchEffect(() => {
    setValue(props.modelValue as number);
  });

  watchEffect(() => {
    setOptions({
      ...defaultOptions,
      valueRange: {
        min: setLimit(props.min),
        max: setLimit(props.max),
      },
    });
  });

  const clamp = (val: number, min?: number, max?: number) => {
    if (min === undefined && max === undefined) {
      return val;
    }
    if (min === undefined) {
      return Math.min(val, max! * BASE_CURRENCY_DIVISION);
    }
    if (max === undefined) {
      return Math.max(val, min * BASE_CURRENCY_DIVISION);
    }
    return Math.min(
      Math.max(val, min * BASE_CURRENCY_DIVISION),
      max * BASE_CURRENCY_DIVISION,
    );
  };

  const handleBlur = () => {
    const value = clamp(numberValue.value!, props.min, props.max);
    emit('blur', value, numberValue.value);
  };
</script>

<template>
  <TextInput
    :test-id="testId"
    ref="inputRef"
    :width="width"
    :error="error"
    :warning="warning"
    :label="label"
    :meta="meta"
    :disabled="disabled"
    :loading="loading"
    v-model="formattedValue"
    :name="name"
    :placeholder="placeholder"
    type="text"
    @blur="handleBlur"
  >
    <template #iconRight><span :class="eurIconClass">€</span></template>
  </TextInput>
</template>
