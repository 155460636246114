<script lang="ts" setup>
  import { useLocale } from '@tundr/i18n';
  import { InputWidthVariant } from '@tundr/ui-kit';
  import { FieldMeta } from 'vee-validate';
  import { computed, useAttrs, useSlots } from 'vue';
  import {
    errorClass,
    inputClass,
    inputContainerClass,
    inputHeaderClass,
    inputLabelClass,
    inputLoaderClass,
    inputWidthClass,
    inputWithLeftIconClass,
    inputWithRightIconClass,
    inputWrapperClass,
    invalidInputClass,
    leftSlotClass,
    notRequiredClass,
    readonlyInputClass,
    rightSlotClass,
    warningClass,
    warningInputClass,
  } from './InputWrapper.css';

  export type InputWrapperProps = {
    id?: string;
    label?: string;
    optionalLabel?: boolean;
    loading?: boolean;
    readonly?: boolean;
    meta?: FieldMeta<unknown>;
    error?: string;
    warning?: string;
    width?: InputWidthVariant;
  };

  const props = withDefaults(defineProps<InputWrapperProps>(), {
    optionalLabel: false,
    disabled: false,
    type: 'text',
    placeholder: '',
    meta: undefined,
  });

  defineOptions({
    inheritAttrs: false,
  });

  const { t } = useLocale('common');
  const slots = useSlots();

  const attrs = useAttrs();

  const showError = computed<boolean>(() => {
    if (props.meta) {
      return !props.meta.valid && props.meta.touched;
    }
    return !!props.error;
  });

  const showWarning = computed<boolean>(() => {
    if (props.meta) {
      return !props.meta.valid && props.meta.touched;
    }
    return !!props.warning;
  });

  const inputClasses = computed(() => ({
    [attrs.class as string]: !!attrs.class,
    [inputClass]: true,
    [warningInputClass]: !showError.value && showWarning.value,
    [invalidInputClass]: showError.value,
    [readonlyInputClass]: props.readonly,
    [inputWithLeftIconClass]: slots?.iconLeft
      ? (slots['iconLeft']()[0]?.children as Array<unknown>)?.length > 0
      : false,
    [inputWithRightIconClass]: slots?.iconRight
      ? (slots['iconRight']()[0]?.children as Array<unknown>)?.length > 0
      : false,
  }));

  const inputContainerClasses = computed<string[]>(() => [
    inputContainerClass,
    inputWidthClass[props.width ?? InputWidthVariant.AUTO],
  ]);
</script>
<template>
  <div :class="inputContainerClasses">
    <slot name="inputLabel">
      <div :class="inputHeaderClass" v-if="label">
        <label v-if="label" :class="inputLabelClass" :for="id">{{
          label
        }}</label>
        <span v-if="optionalLabel" :class="notRequiredClass"
          >({{ t('not_required') }})</span
        >
      </div>
    </slot>
    <div :class="inputWrapperClass">
      <slot :slotClass="inputClasses"></slot>
      <div v-if="slots.iconLeft" :class="leftSlotClass">
        <slot name="iconLeft"></slot>
      </div>
      <div v-if="slots.iconRight" :class="rightSlotClass">
        <slot name="iconRight"></slot>
      </div>
      <div v-if="loading" :class="inputLoaderClass" data-test-id="loader" />
    </div>
    <slot name="inputError">
      <div v-if="showError && error" :class="errorClass">
        {{ error }}
      </div>
    </slot>
    <slot name="inputWarning" v-if="!(showError && error)">
      <div v-if="showWarning && warning" :class="warningClass">
        {{ warning }}
      </div>
    </slot>
  </div>
</template>
