import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const cardRoutesDictionary = moduleRouteDictionaryFactory(
  'card',
  'card',
  {
    cardList: 'card-list',
    cardOrderslist: 'card-orders',
    cardOrdersDetail: 'card-order/:cardOrderId',
    cardRoot: 'cards',
    create: 'create',
  },
);
