<script setup lang="ts">
  import { menuContentClass, menuTriggerClass } from './Menu.css';
  import {
    Popover,
    PopoverCloseTrigger,
    PopoverContent,
    PopoverPositioner,
    PopoverTrigger,
  } from '@ark-ui/vue';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons';
  import Icon from '../Icon/Icon.vue';
  import { useSlots, computed } from 'vue';
  import { GLOBAL_OVERLAYS_TARGET } from '../config/global-overlays-target.ts';
  import { PlacementOptions } from '../utils/placement';

  export type MenuProps = {
    triggerIcon?: ICONS;
    disableTeleport?: boolean;
    closeOnIteract?: boolean;
    open?: boolean;
    placement?: PlacementOptions;
  };

  const props = withDefaults(defineProps<MenuProps>(), {
    disableTeleport: false,
    closeOnIteract: true,
    placement: PlacementOptions.BOTTOM_START,
  });

  // If we erroneously pass an item in the trigger slots and a custom trigger is passed, the item will be ignored
  const slots = useSlots();

  if (!props.triggerIcon && !slots.trigger) {
    throw new Error(
      'DEV ERROR: Menu requires a trigger. It could be a triggerIcon or a slot named "trigger"',
    );
  }

  if (props.triggerIcon && slots.trigger) {
    throw new Error(
      'DEV ERROR: Menu can only have one trigger. It could be a triggerIcon or a slot named "trigger"',
    );
  }

  if (!slots.default) {
    throw new Error('Menu requires a default slot');
  }

  const triggerComponent = computed(
    () => !props.triggerIcon && slots.trigger && slots.trigger()[0],
  );
</script>

<template>
  <Popover
    :isOpen="open"
    :closeOnInteractOutside="true"
    :closeOnEsc="true"
    :positioning="{ placement: placement }"
  >
    <PopoverTrigger data-test-id="menu-trigger" v-if="triggerIcon">
      <div>
        <Icon :icon-name="triggerIcon" :class="menuTriggerClass" />
      </div>
    </PopoverTrigger>
    <PopoverTrigger v-if="!triggerIcon && slots.trigger">
      <component :is="triggerComponent" v-if="triggerComponent" />
    </PopoverTrigger>
    <Teleport :disabled="disableTeleport" :to="`.${GLOBAL_OVERLAYS_TARGET}`">
      <PopoverPositioner style="z-index: 9999">
        <PopoverContent style="outline: none" v-bind="$attrs">
          <PopoverCloseTrigger v-if="closeOnIteract">
            <div data-test-id="menu-content" :class="menuContentClass">
              <slot />
            </div>
          </PopoverCloseTrigger>
          <div v-else data-test-id="menu-content" :class="menuContentClass">
            <slot />
          </div>
        </PopoverContent>
      </PopoverPositioner>
    </Teleport>
  </Popover>
</template>
