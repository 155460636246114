<script setup lang="ts">
  import { HR_THEME_CLASS } from '@tundr/hrTheme';
  import { onMounted } from 'vue';
  import { applicationWrapperClass, environmentChipClass } from './App.css';
  import GlobalElements from './GlobalElements.vue';
  import { ENV_VARS, Environments } from './core/config/env';

  onMounted(() => {
    document.body.classList.add(HR_THEME_CLASS);
  });

  const isNotProduction = ENV_VARS.environment !== Environments.PRODUCTION;
  const environment = ENV_VARS.environment;
  const release = ENV_VARS.release || 'not set';
</script>

<template>
  <GlobalElements />

  <div v-if="isNotProduction" :class="environmentChipClass">
    {{ environment }} - {{ release }}
  </div>
  <div :class="applicationWrapperClass">
    <router-view />
  </div>
</template>

<style>
  @import '@fontsource/poppins/400-italic.css';
  @import '@fontsource/poppins/400.css';
  @import '@fontsource/poppins/500.css';
  @import '@fontsource/poppins/500-italic.css';

  @import '@tundr/theme-assets/font-icon/tundr-icons.css';
  @import '@tundr/theme-assets/unicons/unicons-thinline.css';

  @import 'vue-multiselect/dist/vue-multiselect.css';
</style>
