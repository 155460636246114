import { NotificationMessage } from './../store/notification.types';
import { computed } from 'vue';
import { useNotificationStore } from '../store/notification.store';

export function useNotification() {
  const notificationStore = useNotificationStore();

  const getLastNotification = computed(() => {
    const notifications = notificationStore.notifications;
    return Object.values(notifications).pop();
  });

  const addNotification = (payload: NotificationMessage) => {
    notificationStore.addNotification(payload);
  };

  const removeNotification = (key: string) => {
    notificationStore.removeNotification(key);
  };

  return {
    getLastNotification,
    addNotification,
    removeNotification,
  };
}
