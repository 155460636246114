import { ref } from 'vue';
import { defineStore } from 'pinia';
import { NotificationMessage } from './notification.types';

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref<Record<string, NotificationMessage>>({});

  const addNotification = (payload: NotificationMessage) => {
    notifications.value[payload.key] = payload;
  };

  const removeNotification = (notificationKey: string) => {
    delete notifications.value[notificationKey];
  };

  return {
    notifications,
    addNotification,
    removeNotification,
  };
});
