<script setup lang="ts">
  import { useLocale } from '@tundr/i18n';
  import {
    ModalActions,
    ModalContent,
    ModalSkeleton,
    ModalTitle,
    useModal,
  } from '@tundr/modal';
  import { LinkVariant } from '@tundr/theme';
  import { Checkbox, Link, LinkTarget } from '@tundr/ui-kit';
  import { ref } from 'vue';
  import { useServiceAgreements } from '../../composables/use-service-agreements';
  import PrivacyModal from '../PrivacyModal/PrivacyModal.vue';
  import { privacyLinkClass } from '../PrivacyModal/PrivacyModalClass.css';
  import {
    articlesContainerClass,
    articlesLabelClass,
    articlesListClass,
    articlesListItemClass,
  } from './TermsModal.css';
  import { ENV_VARS } from '../../../../../core/config/env';

  const { t } = useLocale('serviceAgreements');
  const { closeModal, openModal } = useModal();
  const { setPrivacyAcceptance, setTermsAcceptance } = useServiceAgreements();

  const accepted1 = ref<boolean>(false);
  const accepted2 = ref<boolean>(false);

  const onConfirm = () => {
    setPrivacyAcceptance(true);
    setTermsAcceptance(true);
    closeModal();
  };

  const onBack = () => {
    openModal({
      component: PrivacyModal,
    });
  };

  const termsDocumentUrl = ENV_VARS.serviceAgreements.terms;
</script>

<template>
  <ModalSkeleton>
    <template #title>
      <ModalTitle>{{ t('terms.modal_title') }}</ModalTitle>
    </template>
    <ModalContent>
      <Checkbox
        v-model="accepted1"
        name="terms1"
        :value="t('terms.checkbox1_label')"
      />
      <br />
      <Checkbox v-model="accepted2" name="terms2">
        <template #value>
          <div :class="articlesContainerClass">
            <label :class="articlesLabelClass" for="terms2"
              >{{ t('terms.checkbox2_label') }}
              <ul :class="articlesListClass">
                <li :class="articlesListItemClass">
                  {{ t('terms.articles.1') }}
                </li>
                <li :class="articlesListItemClass">
                  {{ t('terms.articles.2') }}
                </li>
                <li :class="articlesListItemClass">
                  {{ t('terms.articles.3') }}
                </li>
                <li :class="articlesListItemClass">
                  {{ t('terms.articles.4') }}
                </li>
                <li :class="articlesListItemClass">
                  {{ t('terms.articles.5') }}
                </li>
                <li :class="articlesListItemClass">
                  {{ t('terms.articles.6') }}
                </li>
                <li :class="articlesListItemClass">
                  {{ t('terms.articles.7') }}
                </li>
                <li :class="articlesListItemClass">
                  {{ t('terms.articles.8') }}
                </li>
              </ul></label
            >
          </div>
        </template>
      </Checkbox>
      <br />
      <Link
        v-if="termsDocumentUrl"
        :title="t('terms.link_text')"
        :href="termsDocumentUrl"
        :variant="LinkVariant.external"
        :target="LinkTarget.BLANK"
        :class="privacyLinkClass"
      >
        {{ t('terms.link_text') }}
      </Link>
    </ModalContent>
    <template #actions>
      <ModalActions
        :confirmLabel="t('common:actions.confirm')"
        :disableConfirm="!accepted1 || !accepted2"
        @confirm="onConfirm"
        @cancel="onBack"
        :cancelLabel="t('common:actions.go_back')"
      />
    </template>
  </ModalSkeleton>
</template>
