import { HttpRequestFn, UseHttpOptions, useHttp } from '@tundr/http';
import {
  CreateMembershipPayload,
  CreateMembershipResponse,
  EditEmployeeAnagraphicDataPayload,
  EditEmployeeAnagraphicDataResponse,
  EditHrAnagraphicDataPayload,
  EditHrAnagraphicDataResponse,
  GetHrVerificationStatusResponse,
  RemoveHrPayload,
  DeleteHrResponse,
} from './accounts-memberships-api.types.ts';

const ACCOUNT_MEMBERSHIP_BASE_API_URL = 'accounts-memberships';

const createMembership: HttpRequestFn<
  CreateMembershipResponse,
  CreateMembershipPayload
> = (http) => (args) => {
  return http.post(`${ACCOUNT_MEMBERSHIP_BASE_API_URL}/hr-user`, args);
};

export const useCreateMembership = (
  args: UseHttpOptions<CreateMembershipResponse, CreateMembershipPayload>,
) =>
  useHttp(createMembership, {
    manual: true,
    ...args,
  });

const editEmployeeAnagraphicData: HttpRequestFn<
  EditEmployeeAnagraphicDataResponse,
  EditEmployeeAnagraphicDataPayload
> =
  (http) =>
  ({ id, ...rest }) => {
    return http.put(
      `${ACCOUNT_MEMBERSHIP_BASE_API_URL}/employees/${id}/user-data`,
      rest,
    );
  };

export const useEditEmployeeAnagraphicData = (
  args: UseHttpOptions<
    EditEmployeeAnagraphicDataResponse,
    EditEmployeeAnagraphicDataPayload
  >,
) =>
  useHttp(editEmployeeAnagraphicData, {
    manual: true,
    ...args,
  });

const editHrAnagraphicData: HttpRequestFn<
  EditHrAnagraphicDataResponse,
  EditHrAnagraphicDataPayload
> =
  (http) =>
  ({ id, ...rest }) => {
    return http.put(
      `${ACCOUNT_MEMBERSHIP_BASE_API_URL}/hrs/${id}/user-data`,
      rest,
    );
  };

export const useEditHrAnagraphicData = (
  args: UseHttpOptions<
    EditHrAnagraphicDataResponse,
    EditHrAnagraphicDataPayload
  >,
) =>
  useHttp(editHrAnagraphicData, {
    manual: true,
    ...args,
  });

// GET HR VERIFICATION STATUS
const getHrVerificationStatus: HttpRequestFn<
  GetHrVerificationStatusResponse,
  void
> = (http) => (params) => {
  return http.get(
    `${ACCOUNT_MEMBERSHIP_BASE_API_URL}/self/bank-provider-status`,
    {
      params,
    },
  );
};

export const useGetHrVerificationStatus = (
  options: UseHttpOptions<GetHrVerificationStatusResponse, void> = {},
) => useHttp(getHrVerificationStatus, options);

// DELETE HR
const deleteHr: HttpRequestFn<DeleteHrResponse, RemoveHrPayload> =
  (http) => (args) => {
    return http.post(
      `${ACCOUNT_MEMBERSHIP_BASE_API_URL}/hrs/${args.hrUserId}/remove`,
      args,
    );
  };

export const useDeleteHr = (
  args: UseHttpOptions<DeleteHrResponse, RemoveHrPayload>,
) =>
  useHttp(deleteHr, {
    manual: true,
    ...args,
  });
