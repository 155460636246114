import { RouteRecordRaw } from 'vue-router';
import { capitalize } from 'vue';

const ROOT_PATH_NAME = 'root';

type StringKeys<T extends Record<string, unknown>> = Extract<keyof T, string>;

type RouteDictionaryConfig = Record<string, string>;

type RouteDictionary = Required<Pick<RouteRecordRaw, 'name' | 'path'>>;

type RouteDictionaryWithoutModule<RouteBaseName extends string> = Record<
  RouteBaseName,
  RouteDictionary
>;

export type ModuleRouteDictionary<
  ModuleName extends string,
  RouteBaseName extends string,
> = Record<ModuleName, RouteDictionaryWithoutModule<RouteBaseName>>;

export function moduleRouteDictionaryFactory<
  ModuleName extends string,
  Config extends RouteDictionaryConfig,
>(
  moduleName: ModuleName,
  moduleBasePath: string,
  routesConfig: Config,
): ModuleRouteDictionary<
  ModuleName,
  typeof ROOT_PATH_NAME | StringKeys<Config>
> {
  return {
    [moduleName]: Object.entries<string>(routesConfig).reduce<
      RouteDictionaryWithoutModule<Extract<keyof Config, string>>
    >(
      (acc, [baseName, path]) => {
        const route: RouteDictionary = {
          name: `${moduleName}${capitalize(baseName)}`,
          path,
        };
        return { ...acc, [baseName]: route };
      },
      {
        [ROOT_PATH_NAME]: {
          name: moduleName,
          path: moduleBasePath,
        },
      } as RouteDictionaryWithoutModule<
        typeof ROOT_PATH_NAME | StringKeys<Config>
      >,
    ),
  } as ModuleRouteDictionary<
    ModuleName,
    typeof ROOT_PATH_NAME | StringKeys<Config>
  >;
}
