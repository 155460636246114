export enum UNICONS_ICONS {
  '0_PLUS' = '0-plus',
  '10_PLUS' = '10-plus',
  '12_PLUS' = '12-plus',
  '13_PLUS' = '13-plus',
  '16_PLUS' = '16-plus',
  '17_PLUS' = '17-plus',
  '18_PLUS' = '18-plus',
  '21_PLUS' = '21-plus',
  '3_PLUS' = '3-plus',
  '6_PLUS' = '6-plus',
  ABACUS = 'abacus',
  ACCESSIBLE_ICON_ALT = 'accessible-icon-alt',
  ADJUST = 'adjust',
  ADJUST_ALT = 'adjust-alt',
  ADJUST_CIRCLE = 'adjust-circle',
  ADJUST_HALF = 'adjust-half',
  ADOBE_ALT = 'adobe-alt',
  AIRPLAY = 'airplay',
  ALIGN_ALT = 'align-alt',
  ALIGN_CENTER = 'align-center',
  ALIGN_CENTER_ALT = 'align-center-alt',
  ALIGN_CENTER_H = 'align-center-h',
  ALIGN_CENTER_JUSTIFY = 'align-center-justify',
  ALIGN_CENTER_V = 'align-center-v',
  ALIGN_JUSTIFY = 'align-justify',
  ALIGN_LEFT = 'align-left',
  ALIGN_LEFT_JUSTIFY = 'align-left-justify',
  ALIGN_LETTER_RIGHT = 'align-letter-right',
  ALIGN_RIGHT = 'align-right',
  ALIGN_RIGHT_JUSTIFY = 'align-right-justify',
  AMBULANCE = 'ambulance',
  ANALYSIS = 'analysis',
  ANALYTICS = 'analytics',
  ANCHOR = 'anchor',
  ANDROID_ALT = 'android-alt',
  ANDROID_PHONE_SLASH = 'android-phone-slash',
  ANGLE_DOUBLE_DOWN = 'angle-double-down',
  ANGLE_DOUBLE_LEFT = 'angle-double-left',
  ANGLE_DOUBLE_RIGHT = 'angle-double-right',
  ANGLE_DOUBLE_UP = 'angle-double-up',
  ANGLE_DOWN = 'angle-down',
  ANGLE_LEFT = 'angle-left',
  ANGLE_LEFT_B = 'angle-left-b',
  ANGLE_RIGHT = 'angle-right',
  ANGLE_RIGHT_B = 'angle-right-b',
  ANGLE_UP = 'angle-up',
  ANGRY = 'angry',
  ANKH = 'ankh',
  ANNOYED = 'annoyed',
  ANNOYED_ALT = 'annoyed-alt',
  APPLE_ALT = 'apple-alt',
  APPS = 'apps',
  ARCHIVE = 'archive',
  ARCHIVE_ALT = 'archive-alt',
  ARCHWAY = 'archway',
  ARROW_BREAK = 'arrow-break',
  ARROW_CIRCLE_DOWN = 'arrow-circle-down',
  ARROW_CIRCLE_LEFT = 'arrow-circle-left',
  ARROW_CIRCLE_RIGHT = 'arrow-circle-right',
  ARROW_CIRCLE_UP = 'arrow-circle-up',
  ARROW_COMPRESS_H = 'arrow-compress-h',
  ARROW_DOWN = 'arrow-down',
  ARROW_DOWN_LEFT = 'arrow-down-left',
  ARROW_DOWN_RIGHT = 'arrow-down-right',
  ARROW_FROM_RIGHT = 'arrow-from-right',
  ARROW_FROM_TOP = 'arrow-from-top',
  ARROW_GROWTH = 'arrow-growth',
  ARROW_LEFT = 'arrow-left',
  ARROW_RANDOM = 'arrow-random',
  ARROW_RESIZE_DIAGONAL = 'arrow-resize-diagonal',
  ARROW_RIGHT = 'arrow-right',
  ARROW_TO_BOTTOM = 'arrow-to-bottom',
  ARROW_TO_RIGHT = 'arrow-to-right',
  ARROW_UP = 'arrow-up',
  ARROW_UP_LEFT = 'arrow-up-left',
  ARROW_UP_RIGHT = 'arrow-up-right',
  ARROWS_H = 'arrows-h',
  ARROWS_H_ALT = 'arrows-h-alt',
  ARROWS_LEFT_DOWN = 'arrows-left-down',
  ARROWS_MAXIMIZE = 'arrows-maximize',
  ARROWS_MERGE = 'arrows-merge',
  ARROWS_RESIZE = 'arrows-resize',
  ARROWS_RESIZE_H = 'arrows-resize-h',
  ARROWS_RESIZE_V = 'arrows-resize-v',
  ARROWS_RIGHT_DOWN = 'arrows-right-down',
  ARROWS_SHRINK_H = 'arrows-shrink-h',
  ARROWS_SHRINK_V = 'arrows-shrink-v',
  ARROWS_UP_RIGHT = 'arrows-up-right',
  ARROWS_V = 'arrows-v',
  ARROWS_V_ALT = 'arrows-v-alt',
  ASSISTIVE_LISTENING_SYSTEMS = 'assistive-listening-systems',
  ASTERISK = 'asterisk',
  AT = 'at',
  ATOM = 'atom',
  AUTO_FLASH = 'auto-flash',
  AWARD = 'award',
  AWARD_ALT = 'award-alt',
  BABY_CARRIAGE = 'baby-carriage',
  BACKPACK = 'backpack',
  BACKSPACE = 'backspace',
  BACKWARD = 'backward',
  BAG = 'bag',
  BAG_ALT = 'bag-alt',
  BAG_SLASH = 'bag-slash',
  BALANCE_SCALE = 'balance-scale',
  BAN = 'ban',
  BAND_AID = 'band-aid',
  BARS = 'bars',
  BASEBALL_BALL = 'baseball-ball',
  BASKETBALL = 'basketball',
  BASKETBALL_HOOP = 'basketball-hoop',
  BATH = 'bath',
  BATTERY_BOLT = 'battery-bolt',
  BATTERY_EMPTY = 'battery-empty',
  BED = 'bed',
  BED_DOUBLE = 'bed-double',
  BEHANCE_ALT = 'behance-alt',
  BELL = 'bell',
  BELL_SCHOOL = 'bell-school',
  BELL_SLASH = 'bell-slash',
  BILL = 'bill',
  BITCOIN_ALT = 'bitcoin-alt',
  BITCOIN_CIRCLE = 'bitcoin-circle',
  BITCOIN_SIGN = 'bitcoin-sign',
  BLOGGER_ALT = 'blogger-alt',
  BLUETOOTH_B = 'bluetooth-b',
  BOLD = 'bold',
  BOLT = 'bolt',
  BOLT_ALT = 'bolt-alt',
  BOLT_SLASH = 'bolt-slash',
  BOOK = 'book',
  BOOK_ALT = 'book-alt',
  BOOK_OPEN = 'book-open',
  BOOK_READER = 'book-reader',
  BOOKMARK = 'bookmark',
  BOOKMARK_FULL = 'bookmark-full',
  BOOKS = 'books',
  BOOMBOX = 'boombox',
  BORDER_ALT = 'border-alt',
  BORDER_BOTTOM = 'border-bottom',
  BORDER_CLEAR = 'border-clear',
  BORDER_HORIZONTAL = 'border-horizontal',
  BORDER_INNER = 'border-inner',
  BORDER_LEFT = 'border-left',
  BORDER_OUT = 'border-out',
  BORDER_TOP = 'border-top',
  BORDER_VERTICAL = 'border-vertical',
  BOWLING_BALL = 'bowling-ball',
  BOX = 'box',
  BRACKETS_CURLY = 'brackets-curly',
  BRAIN = 'brain',
  BRIEFCASE = 'briefcase',
  BRIEFCASE_ALT = 'briefcase-alt',
  BRIGHT = 'bright',
  BRIGHTNESS = 'brightness',
  BRIGHTNESS_EMPTY = 'brightness-empty',
  BRIGHTNESS_HALF = 'brightness-half',
  BRIGHTNESS_LOW = 'brightness-low',
  BRIGHTNESS_PLUS = 'brightness-plus',
  BRING_BOTTOM = 'bring-bottom',
  BRING_FRONT = 'bring-front',
  BROWSER = 'browser',
  BRUSH_ALT = 'brush-alt',
  BUG = 'bug',
  BUILDING = 'building',
  BULLSEYE = 'bullseye',
  BUS = 'bus',
  BUS_ALT = 'bus-alt',
  BUS_SCHOOL = 'bus-school',
  CALCULATOR = 'calculator',
  CALCULATOR_ALT = 'calculator-alt',
  CALENDAR_ALT = 'calendar-alt',
  CALENDAR_SLASH = 'calendar-slash',
  CALENDER = 'calender',
  CALLING = 'calling',
  CAMERA = 'camera',
  CAMERA_CHANGE = 'camera-change',
  CAMERA_PLUS = 'camera-plus',
  CAMERA_SLASH = 'camera-slash',
  CANCEL = 'cancel',
  CAPSULE = 'capsule',
  CAPTURE = 'capture',
  CAR = 'car',
  CAR_SIDEVIEW = 'car-sideview',
  CAR_SLASH = 'car-slash',
  CAR_WASH = 'car-wash',
  CARD_ATM = 'card-atm',
  CARET_RIGHT = 'caret-right',
  CELL = 'cell',
  CELSIUS = 'celsius',
  CHANNEL = 'channel',
  CHANNEL_ADD = 'channel-add',
  CHART = 'chart',
  CHART_BAR = 'chart-bar',
  CHART_BAR_ALT = 'chart-bar-alt',
  CHART_DOWN = 'chart-down',
  CHART_GROWTH = 'chart-growth',
  CHART_GROWTH_ALT = 'chart-growth-alt',
  CHART_LINE = 'chart-line',
  CHART_PIE = 'chart-pie',
  CHART_PIE_ALT = 'chart-pie-alt',
  CHAT = 'chat',
  CHAT_BUBBLE_USER = 'chat-bubble-user',
  CHAT_INFO = 'chat-info',
  CHECK = 'check',
  CHECK_CIRCLE = 'check-circle',
  CHECK_SQUARE = 'check-square',
  CIRCLE = 'circle',
  CIRCLE_LAYER = 'circle-layer',
  CIRCUIT = 'circuit',
  CLAPPER_BOARD = 'clapper-board',
  CLINIC_MEDICAL = 'clinic-medical',
  CLIPBOARD = 'clipboard',
  CLIPBOARD_ALT = 'clipboard-alt',
  CLIPBOARD_BLANK = 'clipboard-blank',
  CLIPBOARD_NOTES = 'clipboard-notes',
  CLOCK = 'clock',
  CLOCK_EIGHT = 'clock-eight',
  CLOCK_FIVE = 'clock-five',
  CLOCK_NINE = 'clock-nine',
  CLOCK_SEVEN = 'clock-seven',
  CLOCK_TEN = 'clock-ten',
  CLOCK_THREE = 'clock-three',
  CLOCK_TWO = 'clock-two',
  CLOSED_CAPTIONING = 'closed-captioning',
  CLOSED_CAPTIONING_SLASH = 'closed-captioning-slash',
  CLOUD = 'cloud',
  CLOUD_BLOCK = 'cloud-block',
  CLOUD_BOOKMARK = 'cloud-bookmark',
  CLOUD_CHECK = 'cloud-check',
  CLOUD_COMPUTING = 'cloud-computing',
  CLOUD_DATA_CONNECTION = 'cloud-data-connection',
  CLOUD_DATABASE_TREE = 'cloud-database-tree',
  CLOUD_DOWNLOAD = 'cloud-download',
  CLOUD_DRIZZLE = 'cloud-drizzle',
  CLOUD_EXCLAMATION = 'cloud-exclamation',
  CLOUD_HAIL = 'cloud-hail',
  CLOUD_HEART = 'cloud-heart',
  CLOUD_INFO = 'cloud-info',
  CLOUD_LOCK = 'cloud-lock',
  CLOUD_MEATBALL = 'cloud-meatball',
  CLOUD_MOON = 'cloud-moon',
  CLOUD_MOON_HAIL = 'cloud-moon-hail',
  CLOUD_MOON_MEATBALL = 'cloud-moon-meatball',
  CLOUD_MOON_RAIN = 'cloud-moon-rain',
  CLOUD_MOON_SHOWERS = 'cloud-moon-showers',
  CLOUD_MOON_TEAR = 'cloud-moon-tear',
  CLOUD_QUESTION = 'cloud-question',
  CLOUD_RAIN = 'cloud-rain',
  CLOUD_RAIN_SUN = 'cloud-rain-sun',
  CLOUD_REDO = 'cloud-redo',
  CLOUD_SHARE = 'cloud-share',
  CLOUD_SHIELD = 'cloud-shield',
  CLOUD_SHOWERS = 'cloud-showers',
  CLOUD_SHOWERS_ALT = 'cloud-showers-alt',
  CLOUD_SHOWERS_HEAVY = 'cloud-showers-heavy',
  CLOUD_SLASH = 'cloud-slash',
  CLOUD_SUN = 'cloud-sun',
  CLOUD_SUN_HAIL = 'cloud-sun-hail',
  CLOUD_SUN_MEATBALL = 'cloud-sun-meatball',
  CLOUD_SUN_RAIN = 'cloud-sun-rain',
  CLOUD_SUN_RAIN_ALT = 'cloud-sun-rain-alt',
  CLOUD_SUN_TEAR = 'cloud-sun-tear',
  CLOUD_TIMES = 'cloud-times',
  CLOUD_UNLOCK = 'cloud-unlock',
  CLOUD_UPLOAD = 'cloud-upload',
  CLOUD_WIFI = 'cloud-wifi',
  CLOUD_WIND = 'cloud-wind',
  CLOUDS = 'clouds',
  CLUB = 'club',
  CODE = 'code',
  CODE_BRANCH = 'code-branch',
  COFFEE = 'coffee',
  COG = 'cog',
  COINS = 'coins',
  COLUMNS = 'columns',
  COMMENT = 'comment',
  COMMENT_ADD = 'comment-add',
  COMMENT_ALT = 'comment-alt',
  COMMENT_ALT_BLOCK = 'comment-alt-block',
  COMMENT_ALT_CHART_LINES = 'comment-alt-chart-lines',
  COMMENT_ALT_CHECK = 'comment-alt-check',
  COMMENT_ALT_DOTS = 'comment-alt-dots',
  COMMENT_ALT_DOWNLOAD = 'comment-alt-download',
  COMMENT_ALT_EDIT = 'comment-alt-edit',
  COMMENT_ALT_EXCLAMATION = 'comment-alt-exclamation',
  COMMENT_ALT_HEART = 'comment-alt-heart',
  COMMENT_ALT_IMAGE = 'comment-alt-image',
  COMMENT_ALT_INFO = 'comment-alt-info',
  COMMENT_ALT_LINRS = 'comment-alt-linrs',
  COMMENT_ALT_LOCK = 'comment-alt-lock',
  COMMENT_ALT_MEDICAL = 'comment-alt-medical',
  COMMENT_ALT_MESSAGE = 'comment-alt-message',
  COMMENT_ALT_NOTES = 'comment-alt-notes',
  COMMENT_ALT_PLUS = 'comment-alt-plus',
  COMMENT_ALT_QUESTION = 'comment-alt-question',
  COMMENT_ALT_REDO = 'comment-alt-redo',
  COMMENT_ALT_SEARCH = 'comment-alt-search',
  COMMENT_ALT_SHARE = 'comment-alt-share',
  COMMENT_ALT_SHIELD = 'comment-alt-shield',
  COMMENT_ALT_SLASH = 'comment-alt-slash',
  COMMENT_ALT_UPLOAD = 'comment-alt-upload',
  COMMENT_ALT_VERIFY = 'comment-alt-verify',
  COMMENT_BLOCK = 'comment-block',
  COMMENT_CHART_LINE = 'comment-chart-line',
  COMMENT_CHECK = 'comment-check',
  COMMENT_DOTS = 'comment-dots',
  COMMENT_DOWNLOAD = 'comment-download',
  COMMENT_EDIT = 'comment-edit',
  COMMENT_EXCLAMATION = 'comment-exclamation',
  COMMENT_HEART = 'comment-heart',
  COMMENT_IMAGE = 'comment-image',
  COMMENT_INFO = 'comment-info',
  COMMENT_INFO_ALT = 'comment-info-alt',
  COMMENT_LINES = 'comment-lines',
  COMMENT_LOCK = 'comment-lock',
  COMMENT_MEDICAL = 'comment-medical',
  COMMENT_MESSAGE = 'comment-message',
  COMMENT_NOTES = 'comment-notes',
  COMMENT_PLUS = 'comment-plus',
  COMMENT_QUESTION = 'comment-question',
  COMMENT_REDO = 'comment-redo',
  COMMENT_SEARCH = 'comment-search',
  COMMENT_SHARE = 'comment-share',
  COMMENT_SHIELD = 'comment-shield',
  COMMENT_SLASH = 'comment-slash',
  COMMENT_UPLOAD = 'comment-upload',
  COMMENT_VERIFY = 'comment-verify',
  COMMENTS = 'comments',
  COMMENTS_ALT = 'comments-alt',
  COMPACT_DISC = 'compact-disc',
  COMPARISON = 'comparison',
  COMPASS = 'compass',
  COMPRESS = 'compress',
  COMPRESS_ALT = 'compress-alt',
  COMPRESS_ALT_LEFT = 'compress-alt-left',
  COMPRESS_ARROWS = 'compress-arrows',
  COMPRESS_LINES = 'compress-lines',
  COMPRESS_POINT = 'compress-point',
  COMPRESS_V = 'compress-v',
  CONFUSED = 'confused',
  CONSTRUCTOR = 'constructor',
  COPY = 'copy',
  COPY_ALT = 'copy-alt',
  COPY_LANDSCAPE = 'copy-landscape',
  COPYRIGHT = 'copyright',
  CORNER_DOWN_LEFT = 'corner-down-left',
  CORNER_DOWN_RIGHT = 'corner-down-right',
  CORNER_DOWN_RIGHT_ALT = 'corner-down-right-alt',
  CORNER_LEFT_DOWN = 'corner-left-down',
  CORNER_RIGHT_DOWN = 'corner-right-down',
  CORNER_UP_LEFT = 'corner-up-left',
  CORNER_UP_LEFT_ALT = 'corner-up-left-alt',
  CORNER_UP_RIGHT = 'corner-up-right',
  CORNER_UP_RIGHT_ALT = 'corner-up-right-alt',
  CORONAVIRUS = 'coronavirus',
  COVID_19 = 'covid-19',
  CREATE_DASHBOARD = 'create-dashboard',
  CREATIVE_COMMONS_PD = 'creative-commons-pd',
  CREDIT_CARD = 'credit-card',
  CREDIT_CARD_SEARCH = 'credit-card-search',
  CROCKERY = 'crockery',
  CROP_ALT = 'crop-alt',
  CROP_ALT_ROTATE_LEFT = 'crop-alt-rotate-left',
  CROP_ALT_ROTATE_RIGHT = 'crop-alt-rotate-right',
  CROSSHAIR = 'crosshair',
  CROSSHAIR_ALT = 'crosshair-alt',
  CROSSHAIRS = 'crosshairs',
  DASHBOARD = 'dashboard',
  DATA_SHARING = 'data-sharing',
  DATABASE = 'database',
  DATABASE_ALT = 'database-alt',
  DESERT = 'desert',
  DESKTOP = 'desktop',
  DESKTOP_ALT = 'desktop-alt',
  DESKTOP_ALT_SLASH = 'desktop-alt-slash',
  DESKTOP_CLOUD_ALT = 'desktop-cloud-alt',
  DESKTOP_SLASH = 'desktop-slash',
  DIALPAD = 'dialpad',
  DIALPAD_ALT = 'dialpad-alt',
  DIAMOND = 'diamond',
  DIARY = 'diary',
  DIARY_ALT = 'diary-alt',
  DICE_FIVE = 'dice-five',
  DICE_FOUR = 'dice-four',
  DICE_ONE = 'dice-one',
  DICE_SIX = 'dice-six',
  DICE_THREE = 'dice-three',
  DICE_TWO = 'dice-two',
  DIRECTION = 'direction',
  DIRECTIONS = 'directions',
  DIZZY_MEH = 'dizzy-meh',
  DNA = 'dna',
  DOCUMENT_INFO = 'document-info',
  DOCUMENT_LAYOUT_CENTER = 'document-layout-center',
  DOCUMENT_LAYOUT_LEFT = 'document-layout-left',
  DOCUMENT_LAYOUT_RIGHT = 'document-layout-right',
  DOLLAR_ALT = 'dollar-alt',
  DOLLAR_SIGN = 'dollar-sign',
  DOLLAR_SIGN_ALT = 'dollar-sign-alt',
  DOWNLOAD_ALT = 'download-alt',
  DRILL = 'drill',
  DROPBOX = 'dropbox',
  DUMBBELL = 'dumbbell',
  EAR = 'ear',
  EDIT = 'edit',
  EDIT_ALT = 'edit-alt',
  ELLIPSIS_H = 'ellipsis-h',
  ELLIPSIS_V = 'ellipsis-v',
  EMOJI = 'emoji',
  ENTER = 'enter',
  ENVELOPE = 'envelope',
  ENVELOPE_ADD = 'envelope-add',
  ENVELOPE_ALT = 'envelope-alt',
  ENVELOPE_BLOCK = 'envelope-block',
  ENVELOPE_BOOKMARK = 'envelope-bookmark',
  ENVELOPE_CHECK = 'envelope-check',
  ENVELOPE_DOWNLOAD = 'envelope-download',
  ENVELOPE_DOWNLOAD_ALT = 'envelope-download-alt',
  ENVELOPE_EDIT = 'envelope-edit',
  ENVELOPE_EXCLAMATION = 'envelope-exclamation',
  ENVELOPE_HEART = 'envelope-heart',
  ENVELOPE_INFO = 'envelope-info',
  ENVELOPE_LOCK = 'envelope-lock',
  ENVELOPE_MINUS = 'envelope-minus',
  ENVELOPE_OPEN = 'envelope-open',
  ENVELOPE_QUESTION = 'envelope-question',
  ENVELOPE_RECEIVE = 'envelope-receive',
  ENVELOPE_REDO = 'envelope-redo',
  ENVELOPE_SEARCH = 'envelope-search',
  ENVELOPE_SEND = 'envelope-send',
  ENVELOPE_SHARE = 'envelope-share',
  ENVELOPE_SHIELD = 'envelope-shield',
  ENVELOPE_STAR = 'envelope-star',
  ENVELOPE_TIMES = 'envelope-times',
  ENVELOPE_UPLOAD = 'envelope-upload',
  ENVELOPE_UPLOAD_ALT = 'envelope-upload-alt',
  ENVELOPES = 'envelopes',
  EQUAL_CIRCLE = 'equal-circle',
  EURO = 'euro',
  EURO_CIRCLE = 'euro-circle',
  EXCHANGE = 'exchange',
  EXCHANGE_ALT = 'exchange-alt',
  EXCLAMATION_CIRCLE = 'exclamation-circle',
  EXCLAMATION_OCTAGON = 'exclamation-octagon',
  EXCLAMATION_TRIANGLE = 'exclamation-triangle',
  EXCLUDE = 'exclude',
  EXPAND_ALT = 'expand-alt',
  EXPAND_ARROWS = 'expand-arrows',
  EXPAND_ARROWS_ALT = 'expand-arrows-alt',
  EXPAND_FROM_CORNER = 'expand-from-corner',
  EXPAND_LEFT = 'expand-left',
  EXPAND_RIGHT = 'expand-right',
  EXPOSURE_ALT = 'exposure-alt',
  EXPOSURE_INCREASE = 'exposure-increase',
  EYE = 'eye',
  EYE_SLASH = 'eye-slash',
  FACEBOOK_F = 'facebook-f',
  FACEBOOK_MESSENGER_ALT = 'facebook-messenger-alt',
  FAHRENHEIT = 'fahrenheit',
  FAST_MAIL = 'fast-mail',
  FAST_MAIL_ALT = 'fast-mail-alt',
  FAVORITE = 'favorite',
  FEEDBACK = 'feedback',
  FILE = 'file',
  FILE_ALT = 'file-alt',
  FILE_BLANK = 'file-blank',
  FILE_BLOCK_ALT = 'file-block-alt',
  FILE_BOOKMARK_ALT = 'file-bookmark-alt',
  FILE_CHECK = 'file-check',
  FILE_CHECK_ALT = 'file-check-alt',
  FILE_CONTRACT_DOLLAR = 'file-contract-dollar',
  FILE_COPY_ALT = 'file-copy-alt',
  FILE_DOWNLOAD = 'file-download',
  FILE_DOWNLOAD_ALT = 'file-download-alt',
  FILE_EDIT_ALT = 'file-edit-alt',
  FILE_EXCLAMATION = 'file-exclamation',
  FILE_EXCLAMATION_ALT = 'file-exclamation-alt',
  FILE_EXPORT = 'file-export',
  FILE_GRAPH = 'file-graph',
  FILE_HEART = 'file-heart',
  FILE_IMPORT = 'file-import',
  FILE_INFO_ALT = 'file-info-alt',
  FILE_LANDSCAPE = 'file-landscape',
  FILE_LANDSCAPE_ALT = 'file-landscape-alt',
  FILE_LANSCAPE_SLASH = 'file-lanscape-slash',
  FILE_LOCK_ALT = 'file-lock-alt',
  FILE_MEDICAL = 'file-medical',
  FILE_MEDICAL_ALT = 'file-medical-alt',
  FILE_MINUS = 'file-minus',
  FILE_MINUS_ALT = 'file-minus-alt',
  FILE_NETWORK = 'file-network',
  FILE_PLUS_ALT = 'file-plus-alt',
  FILE_QUESTION = 'file-question',
  FILE_QUESTION_ALT = 'file-question-alt',
  FILE_REDO_ALT = 'file-redo-alt',
  FILE_SEARCH_ALT = 'file-search-alt',
  FILE_SHARE_ALT = 'file-share-alt',
  FILE_SHIELD_ALT = 'file-shield-alt',
  FILE_SLASH = 'file-slash',
  FILE_TIMES = 'file-times',
  FILE_TIMES_ALT = 'file-times-alt',
  FILE_UPLOAD = 'file-upload',
  FILE_UPLOAD_ALT = 'file-upload-alt',
  FILES_LANDSCAPES = 'files-landscapes',
  FILES_LANDSCAPES_ALT = 'files-landscapes-alt',
  FILM = 'film',
  FILTER = 'filter',
  FILTER_SLASH = 'filter-slash',
  FIRE = 'fire',
  FLASK = 'flask',
  FLASK_POTION = 'flask-potion',
  FLIP_H = 'flip-h',
  FLIP_H_ALT = 'flip-h-alt',
  FLIP_V = 'flip-v',
  FLIP_V_ALT = 'flip-v-alt',
  FLOWER = 'flower',
  FOCUS = 'focus',
  FOCUS_ADD = 'focus-add',
  FOCUS_TARGET = 'focus-target',
  FOLDER = 'folder',
  FOLDER_CHECK = 'folder-check',
  FOLDER_DOWNLOAD = 'folder-download',
  FOLDER_EXCLAMATION = 'folder-exclamation',
  FOLDER_HEART = 'folder-heart',
  FOLDER_INFO = 'folder-info',
  FOLDER_LOCK = 'folder-lock',
  FOLDER_MEDICAL = 'folder-medical',
  FOLDER_MINUS = 'folder-minus',
  FOLDER_NETWORK = 'folder-network',
  FOLDER_OPEN = 'folder-open',
  FOLDER_QUESTION = 'folder-question',
  FOLDER_SLASH = 'folder-slash',
  FOLDER_TIMES = 'folder-times',
  FOLDER_UPLOAD = 'folder-upload',
  FONT = 'font',
  FOOD = 'food',
  FOOTBALL = 'football',
  FOOTBALL_AMERICAN = 'football-american',
  FORWADED_CALL = 'forwaded-call',
  FORWARD = 'forward',
  FROWN = 'frown',
  GAME_STRUCTURE = 'game-structure',
  GIFT = 'gift',
  GITHUB_ALT = 'github-alt',
  GLASS = 'glass',
  GLASS_MARTINI = 'glass-martini',
  GLASS_MARTINI_ALT = 'glass-martini-alt',
  GLASS_MARTINI_ALT_SLASH = 'glass-martini-alt-slash',
  GLASS_TEA = 'glass-tea',
  GLOBE = 'globe',
  GOLD = 'gold',
  GOOGLE = 'google',
  GOOGLE_DRIVE_ALT = 'google-drive-alt',
  GOOGLE_HANGOUTS_ALT = 'google-hangouts-alt',
  GOOGLE_PLAY = 'google-play',
  GRADUATION_CAP = 'graduation-cap',
  GRAPH_BAR = 'graph-bar',
  GRID = 'grid',
  GRIDS = 'grids',
  GRIN = 'grin',
  GRIN_TONGUE_WINK = 'grin-tongue-wink',
  GRIN_TONGUE_WINK_ALT = 'grin-tongue-wink-alt',
  GRIP_HORIZONTAL_LINE = 'grip-horizontal-line',
  HARD_HAT = 'hard-hat',
  HDD = 'hdd',
  HEAD_SIDE = 'head-side',
  HEAD_SIDE_COUGH = 'head-side-cough',
  HEAD_SIDE_MASK = 'head-side-mask',
  HEADPHONES = 'headphones',
  HEADPHONES_ALT = 'headphones-alt',
  HEART = 'heart',
  HEART_BREAK = 'heart-break',
  HEART_MEDICAL = 'heart-medical',
  HEART_RATE = 'heart-rate',
  HEART_SIGN = 'heart-sign',
  HEARTBEAT = 'heartbeat',
  HISTORY = 'history',
  HISTORY_ALT = 'history-alt',
  HOME = 'home',
  HOME_ALT = 'home-alt',
  HORIZONTAL_ALIGN_CENTER = 'horizontal-align-center',
  HORIZONTAL_ALIGN_LEFT = 'horizontal-align-left',
  HORIZONTAL_ALIGN_RIGHT = 'horizontal-align-right',
  HORIZONTAL_DISTRIBUTION_CENTER = 'horizontal-distribution-center',
  HORIZONTAL_DISTRIBUTION_LEFT = 'horizontal-distribution-left',
  HORIZONTAL_DISTRIBUTION_RIGHT = 'horizontal-distribution-right',
  HOSPITAL = 'hospital',
  HOSPITAL_SQUARE_SIGN = 'hospital-square-sign',
  HOSPITAL_SYMBOL = 'hospital-symbol',
  HOURGLASS = 'hourglass',
  HOUSE_USER = 'house-user',
  HTML3_ALT = 'html3-alt',
  HUNTING = 'hunting',
  IMAGE = 'image',
  IMAGE_ALT_SLASH = 'image-alt-slash',
  IMAGE_BLOCK = 'image-block',
  IMAGE_BROKEN = 'image-broken',
  IMAGE_CHECK = 'image-check',
  IMAGE_DOWNLOAD = 'image-download',
  IMAGE_EDIT = 'image-edit',
  IMAGE_INFO = 'image-info',
  IMAGE_LOCK = 'image-lock',
  IMAGE_MINUS = 'image-minus',
  IMAGE_PLUS = 'image-plus',
  IMAGE_QUESTION = 'image-question',
  IMAGE_REDO = 'image-redo',
  IMAGE_RESIZE_LANDSCAPE = 'image-resize-landscape',
  IMAGE_RESIZE_SQUARE = 'image-resize-square',
  IMAGE_SEARCH = 'image-search',
  IMAGE_SHARE = 'image-share',
  IMAGE_SHIELD = 'image-shield',
  IMAGE_SLASH = 'image-slash',
  IMAGE_TIMES = 'image-times',
  IMAGE_UPLOAD = 'image-upload',
  IMAGE_V = 'image-v',
  IMAGES = 'images',
  IMPORT = 'import',
  INCOMING_CALL = 'incoming-call',
  INFO_CIRCLE = 'info-circle',
  INTERCOM_ALT = 'intercom-alt',
  INVOICE = 'invoice',
  ITALIC = 'italic',
  JACKHAMMER = 'jackhammer',
  KAYAK = 'kayak',
  KEY_SKELETON = 'key-skeleton',
  KEY_SKELETON_ALT = 'key-skeleton-alt',
  KEYBOARD_ALT = 'keyboard-alt',
  KEYBOARD_HIDE = 'keyboard-hide',
  KEYBOARD_SHOW = 'keyboard-show',
  KEYHOLE_CIRCLE = 'keyhole-circle',
  KEYHOLE_SQUARE = 'keyhole-square',
  KEYHOLE_SQUARE_FULL = 'keyhole-square-full',
  KID = 'kid',
  LABEL = 'label',
  LABEL_ALT = 'label-alt',
  LAPTOP = 'laptop',
  LAPTOP_CLOUD = 'laptop-cloud',
  LAPTOP_CONNECTION = 'laptop-connection',
  LAUGHING = 'laughing',
  LAYER_GROUP = 'layer-group',
  LAYER_GROUP_SLASH = 'layer-group-slash',
  LAYERS = 'layers',
  LAYERS_ALT = 'layers-alt',
  LAYERS_SLASH = 'layers-slash',
  LEFT_ARROW_FROM_LEFT = 'left-arrow-from-left',
  LEFT_ARROW_TO_LEFT = 'left-arrow-to-left',
  LEFT_INDENT = 'left-indent',
  LEFT_INDENT_ALT = 'left-indent-alt',
  LEFT_TO_RIGHT_TEXT_DIRECTION = 'left-to-right-text-direction',
  LIFE_RING = 'life-ring',
  LIGHTBULB = 'lightbulb',
  LIGHTBULB_ALT = 'lightbulb-alt',
  LINE_ALT = 'line-alt',
  LINE_SPACING = 'line-spacing',
  LINK = 'link',
  LINK_ADD = 'link-add',
  LINK_ALT = 'link-alt',
  LINK_BROKEN = 'link-broken',
  LINK_H = 'link-h',
  LINKEDIN_ALT = 'linkedin-alt',
  LIST_UI_ALT = 'list-ui-alt',
  LIST_UL = 'list-ul',
  LOCATION = 'location',
  LOCATION_ARROW = 'location-arrow',
  LOCATION_ARROW_ALT = 'location-arrow-alt',
  LOCATION_PIN_ALT = 'location-pin-alt',
  LOCATION_POINT = 'location-point',
  LOCK = 'lock',
  LOCK_ACCESS = 'lock-access',
  LOCK_ALT = 'lock-alt',
  LOCK_OPEN_ALT = 'lock-open-alt',
  LOCK_SLASH = 'lock-slash',
  LUGGAGE_CART = 'luggage-cart',
  MAILBOX = 'mailbox',
  MAILBOX_ALT = 'mailbox-alt',
  MAP = 'map',
  MAP_MARKER = 'map-marker',
  MAP_MARKER_ALT = 'map-marker-alt',
  MAP_MARKER_EDIT = 'map-marker-edit',
  MAP_MARKER_INFO = 'map-marker-info',
  MAP_MARKER_MINUS = 'map-marker-minus',
  MAP_MARKER_PLUS = 'map-marker-plus',
  MAP_MARKER_QUESTION = 'map-marker-question',
  MAP_MARKER_SHIELD = 'map-marker-shield',
  MAP_MARKER_SLASH = 'map-marker-slash',
  MAP_PIN = 'map-pin',
  MAP_PIN_ALT = 'map-pin-alt',
  MARS = 'mars',
  MASTER_CARD = 'master-card',
  MAXIMIZE_LEFT = 'maximize-left',
  MEDAL = 'medal',
  MEDICAL_DRIP = 'medical-drip',
  MEDICAL_SQUARE = 'medical-square',
  MEDICAL_SQUARE_FULL = 'medical-square-full',
  MEDKIT = 'medkit',
  MEETING_BOARD = 'meeting-board',
  MEGAPHONE = 'megaphone',
  MEH = 'meh',
  MEH_ALT = 'meh-alt',
  MEH_CLOSED_EYE = 'meh-closed-eye',
  MESSAGE = 'message',
  METRO = 'metro',
  MICROPHONE = 'microphone',
  MICROPHONE_SLASH = 'microphone-slash',
  MICROSCOPE = 'microscope',
  MINUS = 'minus',
  MINUS_CIRCLE = 'minus-circle',
  MINUS_PATH = 'minus-path',
  MINUS_SQUARE = 'minus-square',
  MINUS_SQUARE_FULL = 'minus-square-full',
  MISSED_CALL = 'missed-call',
  MOBILE_ANDROID = 'mobile-android',
  MOBILE_ANDROID_ALT = 'mobile-android-alt',
  MOBILE_VIBRATE = 'mobile-vibrate',
  MODEM = 'modem',
  MONEY_BILL = 'money-bill',
  MONEY_BILL_SLASH = 'money-bill-slash',
  MONEY_BILL_STACK = 'money-bill-stack',
  MONEY_INSERT = 'money-insert',
  MONEY_STACK = 'money-stack',
  MONEY_WITHDRAW = 'money-withdraw',
  MONEY_WITHDRAWAL = 'money-withdrawal',
  MONEYBAG = 'moneybag',
  MONEYBAG_ALT = 'moneybag-alt',
  MONITOR = 'monitor',
  MONITOR_HEART_RATE = 'monitor-heart-rate',
  MOON = 'moon',
  MOON_ECLIPSE = 'moon-eclipse',
  MOONSET = 'moonset',
  MOUNTAINS = 'mountains',
  MOUNTAINS_SUN = 'mountains-sun',
  MOUSE = 'mouse',
  MOUSE_ALT = 'mouse-alt',
  MOUSE_ALT_2 = 'mouse-alt-2',
  MULTIPLY = 'multiply',
  MUSIC = 'music',
  MUSIC_NOTE = 'music-note',
  MUSIC_TUNE_SLASH = 'music-tune-slash',
  N_A = 'n-a',
  NAVIGATOR = 'navigator',
  NERD = 'nerd',
  NEWSPAPER = 'newspaper',
  NINJA = 'ninja',
  NO_ENTRY = 'no-entry',
  NOTES = 'notes',
  OBJECT_GROUP = 'object-group',
  OBJECT_UNGROUP = 'object-ungroup',
  OCTAGON = 'octagon',
  OPERA_ALT = 'opera-alt',
  OUTGOING_CALL = 'outgoing-call',
  PACKAGE = 'package',
  PADLOCK = 'padlock',
  PAINT_TOOL = 'paint-tool',
  PALETTE = 'palette',
  PANEL_ADD = 'panel-add',
  PANORAMA_H = 'panorama-h',
  PANORAMA_H_ALT = 'panorama-h-alt',
  PANORAMA_V = 'panorama-v',
  PAPERCLIP = 'paperclip',
  PARAGRAPH = 'paragraph',
  PARCEL = 'parcel',
  PARKING_CIRCLE = 'parking-circle',
  PARKING_SQUARE = 'parking-square',
  PATHFINDER = 'pathfinder',
  PATHFINDER_UNITE = 'pathfinder-unite',
  PAUSE = 'pause',
  PAUSE_CIRCLE = 'pause-circle',
  PAYPAL = 'paypal',
  PEN = 'pen',
  PENTAGON = 'pentagon',
  PERCENTAGE = 'percentage',
  PHONE_ALT = 'phone-alt',
  PHONE_PAUSE = 'phone-pause',
  PHONE_SLASH = 'phone-slash',
  PHONE_TIMES = 'phone-times',
  PHONE_VOLUME = 'phone-volume',
  PLANE = 'plane',
  PLANE_ARRIVAL = 'plane-arrival',
  PLANE_DEPARTURE = 'plane-departure',
  PLANE_FLY = 'plane-fly',
  PLAY = 'play',
  PLAY_CIRCLE = 'play-circle',
  PLUG = 'plug',
  PLUS = 'plus',
  PLUS_CIRCLE = 'plus-circle',
  PLUS_SQUARE = 'plus-square',
  PODIUM = 'podium',
  POLYGON = 'polygon',
  POST_STAMP = 'post-stamp',
  POSTCARD = 'postcard',
  POUND = 'pound',
  POUND_CIRCLE = 'pound-circle',
  POWER = 'power',
  PRESCRIPTION_BOTTLE = 'prescription-bottle',
  PRESENTATION = 'presentation',
  PRESENTATION_CHECK = 'presentation-check',
  PRESENTATION_EDIT = 'presentation-edit',
  PRESENTATION_LINE = 'presentation-line',
  PRESENTATION_LINES_ALT = 'presentation-lines-alt',
  PRESENTATION_MINUS = 'presentation-minus',
  PRESENTATION_PLAY = 'presentation-play',
  PRESENTATION_PLUS = 'presentation-plus',
  PRESENTATION_TIMES = 'presentation-times',
  PREVIOUS = 'previous',
  PRICETAG_ALT = 'pricetag-alt',
  PRINT = 'print',
  PRINT_SLASH = 'print-slash',
  PROCESS = 'process',
  PROCESSOR = 'processor',
  PUMP = 'pump',
  PUZZLE_PIECE = 'puzzle-piece',
  QRCODE_SCAN = 'qrcode-scan',
  QUESTION_CIRCLE = 'question-circle',
  RAINBOW = 'rainbow',
  RAINDROPS = 'raindrops',
  RAINDROPS_ALT = 'raindrops-alt',
  RECEIPT = 'receipt',
  RECEIPT_ALT = 'receipt-alt',
  RECORD_AUDIO = 'record-audio',
  REDDIT_ALIEN_ALT = 'reddit-alien-alt',
  REDO = 'redo',
  REFRESH = 'refresh',
  REGISTERED = 'registered',
  REPEAT = 'repeat',
  RESTAURANT = 'restaurant',
  RIGHT_INDENT = 'right-indent',
  RIGHT_INDENT_ALT = 'right-indent-alt',
  RIGHT_TO_LEFT_TEXT_DIRECTION = 'right-to-left-text-direction',
  ROBOT = 'robot',
  ROCKET = 'rocket',
  ROPE_WAY = 'rope-way',
  ROTATE = 'rotate',
  RSS = 'rss',
  RSS_ALT = 'rss-alt',
  RSS_INTERFACE = 'rss-interface',
  RULER = 'ruler',
  RULER_COMBINED = 'ruler-combined',
  RUPEE_SIGN = 'rupee-sign',
  SAD = 'sad',
  SAD_CRY = 'sad-cry',
  SAD_CRYING = 'sad-crying',
  SAD_DIZZY = 'sad-dizzy',
  SAD_SQUINT = 'sad-squint',
  SANITIZER = 'sanitizer',
  SANITIZER_ALT = 'sanitizer-alt',
  SAVE = 'save',
  SCALING_LEFT = 'scaling-left',
  SCALING_RIGHT = 'scaling-right',
  SCENERY = 'scenery',
  SCHEDULE = 'schedule',
  SCREW = 'screw',
  SCROLL = 'scroll',
  SCROLL_H = 'scroll-h',
  SEARCH = 'search',
  SEARCH_ALT = 'search-alt',
  SEARCH_MINUS = 'search-minus',
  SEARCH_PLUS = 'search-plus',
  SELFIE = 'selfie',
  SERVER = 'server',
  SERVER_ALT = 'server-alt',
  SERVER_CONNECTION = 'server-connection',
  SERVER_NETWORK = 'server-network',
  SERVER_NETWORK_ALT = 'server-network-alt',
  SERVERS = 'servers',
  SERVICEMARK = 'servicemark',
  SETTING = 'setting',
  SHARE = 'share',
  SHARE_ALT = 'share-alt',
  SHIELD = 'shield',
  SHIELD_CHECK = 'shield-check',
  SHIELD_EXCLAMATION = 'shield-exclamation',
  SHIELD_PLUS = 'shield-plus',
  SHIELD_QUESTION = 'shield-question',
  SHIELD_SLASH = 'shield-slash',
  SHIP = 'ship',
  SHOP = 'shop',
  SHOPPING_BAG = 'shopping-bag',
  SHOPPING_BASKET = 'shopping-basket',
  SHOPPING_CART = 'shopping-cart',
  SHOPPING_CART_ALT = 'shopping-cart-alt',
  SHOVEL = 'shovel',
  SHRINK = 'shrink',
  SHUFFLE = 'shuffle',
  SHUTTER = 'shutter',
  SHUTTER_ALT = 'shutter-alt',
  SICK = 'sick',
  SIGMA = 'sigma',
  SIGN_ALT = 'sign-alt',
  SIGN_LEFT = 'sign-left',
  SIGN_OUT_ALT = 'sign-out-alt',
  SIGN_RIGHT = 'sign-right',
  SIGNAL = 'signal',
  SIGNAL_ALT = 'signal-alt',
  SIGNAL_ALT_3 = 'signal-alt-3',
  SIGNIN = 'signin',
  SIGNIN_ALT = 'signin-alt',
  SIGNOUT = 'signout',
  SILENCE = 'silence',
  SILENT_SQUINT = 'silent-squint',
  SIM_CARD = 'sim-card',
  SITEMAP = 'sitemap',
  SKIP_FORWARD = 'skip-forward',
  SKIP_FORWARD_ALT = 'skip-forward-alt',
  SKIP_FORWARD_CIRCLE = 'skip-forward-circle',
  SKYPE_ALT = 'skype-alt',
  SLACK_ALT = 'slack-alt',
  SLIDERS_V = 'sliders-v',
  SLIDERS_V_ALT = 'sliders-v-alt',
  SMILE = 'smile',
  SMILE_BEAM = 'smile-beam',
  SMILE_DIZZY = 'smile-dizzy',
  SMILE_SQUINT_WINK = 'smile-squint-wink',
  SMILE_SQUINT_WINK_ALT = 'smile-squint-wink-alt',
  SMILE_WINK = 'smile-wink',
  SMILE_WINK_ALT = 'smile-wink-alt',
  SNAPCHAT_ALT = 'snapchat-alt',
  SNOW_FLAKE = 'snow-flake',
  SNOWFLAKE = 'snowflake',
  SNOWFLAKE_ALT = 'snowflake-alt',
  SOCIAL_DISTANCING = 'social-distancing',
  SORT = 'sort',
  SORT_AMOUNT_DOWN = 'sort-amount-down',
  SORT_AMOUNT_UP = 'sort-amount-up',
  SORTING = 'sorting',
  SPACE_KEY = 'space-key',
  SPADE = 'spade',
  SPERMS = 'sperms',
  SPIN = 'spin',
  SPORT = 'sport',
  SQUARE = 'square',
  SQUARE_FULL = 'square-full',
  SQUARE_SHAPE = 'square-shape',
  SQUINT = 'squint',
  STAR = 'star',
  STAR_HALF_ALT = 'star-half-alt',
  STEP_BACKWARD = 'step-backward',
  STEP_BACKWARD_ALT = 'step-backward-alt',
  STEP_BACKWARD_CIRCLE = 'step-backward-circle',
  STEP_FORWARD = 'step-forward',
  STETHOSCOPE = 'stethoscope',
  STETHOSCOPE_ALT = 'stethoscope-alt',
  STOP_CIRCLE = 'stop-circle',
  STOPWATCH = 'stopwatch',
  STOPWATCH_SLASH = 'stopwatch-slash',
  STORE = 'store',
  STORE_ALT = 'store-alt',
  STORE_SLASH = 'store-slash',
  STREERING = 'streering',
  STRETCHER = 'stretcher',
  SUBJECT = 'subject',
  SUBWAY = 'subway',
  SUBWAY_ALT = 'subway-alt',
  SUITCASE = 'suitcase',
  SUITCASE_ALT = 'suitcase-alt',
  SUN = 'sun',
  SUNSET = 'sunset',
  SURPRISE = 'surprise',
  SWATCHBOOK = 'swatchbook',
  SWIMMER = 'swimmer',
  SYNC = 'sync',
  SYNC_EXCLAMATION = 'sync-exclamation',
  SYNC_SLASH = 'sync-slash',
  SYRINGE = 'syringe',
  TABLE = 'table',
  TABLE_LAMP = 'table-lamp',
  TABLE_TENNIS = 'table-tennis',
  TABLET = 'tablet',
  TABLETS = 'tablets',
  TACHOMETER_FAST = 'tachometer-fast',
  TACHOMETER_FAST_ALT = 'tachometer-fast-alt',
  TAG = 'tag',
  TAG_ALT = 'tag-alt',
  TAPE = 'tape',
  TAXI = 'taxi',
  TEAR = 'tear',
  TELEGRAM_ALT = 'telegram-alt',
  TELESCOPE = 'telescope',
  TEMPERATURE = 'temperature',
  TEMPERATURE_EMPTY = 'temperature-empty',
  TEMPERATURE_HALF = 'temperature-half',
  TEMPERATURE_MINUS = 'temperature-minus',
  TEMPERATURE_PLUS = 'temperature-plus',
  TEMPERATURE_QUARTER = 'temperature-quarter',
  TEMPERATURE_THREE_QUARTER = 'temperature-three-quarter',
  TENNIS_BALL = 'tennis-ball',
  TEXT = 'text',
  TEXT_FIELDS = 'text-fields',
  TEXT_SIZE = 'text-size',
  TEXT_STRIKE_THROUGH = 'text-strike-through',
  TH = 'th',
  TH_LARGE = 'th-large',
  TH_SLASH = 'th-slash',
  THERMOMETER = 'thermometer',
  THUMBS_DOWN = 'thumbs-down',
  THUMBS_UP = 'thumbs-up',
  THUNDERSTORM = 'thunderstorm',
  THUNDERSTORM_MOON = 'thunderstorm-moon',
  THUNDERSTORM_SUN = 'thunderstorm-sun',
  TICKET = 'ticket',
  TIMES = 'times',
  TIMES_CIRCLE = 'times-circle',
  TIMES_SQUARE = 'times-square',
  TOGGLE_OFF = 'toggle-off',
  TOGGLE_ON = 'toggle-on',
  TOILET_PAPER = 'toilet-paper',
  TOP_ARROW_FROM_TOP = 'top-arrow-from-top',
  TOP_ARROW_TO_TOP = 'top-arrow-to-top',
  TORNADO = 'tornado',
  TRADEMARK = 'trademark',
  TRADEMARK_CIRCLE = 'trademark-circle',
  TRAFFIC_BARRIER = 'traffic-barrier',
  TRAFFIC_LIGHT = 'traffic-light',
  TRANSACTION = 'transaction',
  TRASH = 'trash',
  TRASH_ALT = 'trash-alt',
  TREES = 'trees',
  TRIANGLE = 'triangle',
  TROPHY = 'trophy',
  TROWEL = 'trowel',
  TRUCK = 'truck',
  TRUCK_LOADING = 'truck-loading',
  TUMBLR_ALT = 'tumblr-alt',
  TV_RETRO = 'tv-retro',
  TV_RETRO_SLASH = 'tv-retro-slash',
  TWITTER_ALT = 'twitter-alt',
  UMBRELLA = 'umbrella',
  UNAMUSED = 'unamused',
  UNDERLINE = 'underline',
  UNIVERSITY = 'university',
  UNLOCK = 'unlock',
  UNLOCK_ALT = 'unlock-alt',
  UPLOAD = 'upload',
  UPLOAD_ALT = 'upload-alt',
  USD_CIRCLE = 'usd-circle',
  USD_SQUARE = 'usd-square',
  USER = 'user',
  USER_ARROWS = 'user-arrows',
  USER_CHECK = 'user-check',
  USER_CIRCLE = 'user-circle',
  USER_EXCLAMATION = 'user-exclamation',
  USER_MD = 'user-md',
  USER_MINUS = 'user-minus',
  USER_NURSE = 'user-nurse',
  USER_PLUS = 'user-plus',
  USER_SQUARE = 'user-square',
  USER_TIMES = 'user-times',
  USERS_ALT = 'users-alt',
  UTENSILS = 'utensils',
  UTENSILS_ALT = 'utensils-alt',
  VECTOR_SQUARE = 'vector-square',
  VECTOR_SQUARE_ALT = 'vector-square-alt',
  VENUS = 'venus',
  VERTICAL_ALIGN_BOTTOM = 'vertical-align-bottom',
  VERTICAL_ALIGN_CENTER = 'vertical-align-center',
  VERTICAL_ALIGN_TOP = 'vertical-align-top',
  VERTICAL_DISTRIBUTE_BOTTOM = 'vertical-distribute-bottom',
  VERTICAL_DISTRIBUTION_CENTER = 'vertical-distribution-center',
  VERTICAL_DISTRIBUTION_TOP = 'vertical-distribution-top',
  VIDEO = 'video',
  VIDEO_SLASH = 'video-slash',
  VK_ALT = 'vk-alt',
  VOICEMAIL = 'voicemail',
  VOICEMAIL_RECTANGLE = 'voicemail-rectangle',
  VOLLEYBALL = 'volleyball',
  VOLUME = 'volume',
  VOLUME_DOWN = 'volume-down',
  VOLUME_MUTE = 'volume-mute',
  VOLUME_OFF = 'volume-off',
  VOLUME_UP = 'volume-up',
  VUEJS_ALT = 'vuejs-alt',
  WALL = 'wall',
  WALLET = 'wallet',
  WATCH = 'watch',
  WATCH_ALT = 'watch-alt',
  WATER = 'water',
  WATER_DROP_SLASH = 'water-drop-slash',
  WATER_GLASS = 'water-glass',
  WEB_GRID = 'web-grid',
  WEB_GRID_ALT = 'web-grid-alt',
  WEB_SECTION = 'web-section',
  WEB_SECTION_ALT = 'web-section-alt',
  WEBCAM = 'webcam',
  WEIGHT = 'weight',
  WHEEL_BARROW = 'wheel-barrow',
  WHEELCHAIR = 'wheelchair',
  WHEELCHAIR_ALT = 'wheelchair-alt',
  WIFI = 'wifi',
  WIFI_ROUTER = 'wifi-router',
  WIFI_SLASH = 'wifi-slash',
  WIND = 'wind',
  WIND_MOON = 'wind-moon',
  WIND_SUN = 'wind-sun',
  WINDOW = 'window',
  WINDOW_GRID = 'window-grid',
  WINDOW_MAXIMIZE = 'window-maximize',
  WINDOW_SECTION = 'window-section',
  WINDSOCK = 'windsock',
  WINDY = 'windy',
  WRAP_TEXT = 'wrap-text',
  WRENCH = 'wrench',
  X = 'x',
  X_ADD = 'x-add',
  YEN = 'yen',
  YEN_CIRCLE = 'yen-circle',
  YIN_YANG = 'yin-yang',
  YOUTUBE = 'youtube',
}
