import { defineStore } from 'pinia';
import { Component, shallowRef } from 'vue';
import { SidePanelConfig } from '../components/SidePanel';

export interface SidePanelStoreProps {
  component: null | Component;
  props?: Record<string, unknown>;
  config?: SidePanelConfig;
  events?: {
    onClose?: () => void;
    onConfirm?: () => void;
    onCancel?: () => void;
  };
  labels?: {
    confirm?: string;
    cancel?: string;
  };
}

const initialState = {
  component: null,
  props: {},
  config: { blocking: false, firstSelectionOnLastItem: true },
};

export const useSidePanelStore = defineStore('side-panel', () => {
  const sidePanelState = shallowRef<SidePanelStoreProps>(initialState);

  const openSidePanel = ({
    component,
    props = {},
    config = { blocking: false, firstSelectionOnLastItem: true },
    events = {},
    labels = {},
  }: SidePanelStoreProps) => {
    sidePanelState.value = {
      component,
      props,
      config,
      events,
      labels,
    };
  };

  const closeSidePanel = () => {
    if (sidePanelState.value.events?.onClose) {
      sidePanelState.value.events.onClose();
    }
    sidePanelState.value = initialState;
  };

  return { sidePanelState, openSidePanel, closeSidePanel };
});
