<script lang="ts" setup>
  import { useToastStore } from '../../store/toast.store.ts';
  import Toast from '../Toast/Toast.vue';
  import { computed } from 'vue';
  import { animated, slideInRight, slideOutRight } from '@tundr/theme';
  import { toastPositionClass } from './ToastPresenter.css.ts';

  const store = useToastStore();

  const enterActiveClass = computed(() => `${animated} ${slideInRight}`);
  const leaveActiveClass = computed(() => `${animated} ${slideOutRight}`);

  const getToastTopPosition = (index: number) => {
    const TOAST_HEIGHT = 100;
    const OFFSET = 20;
    if (index === 0) return OFFSET;
    if (index === 1) return TOAST_HEIGHT + OFFSET;
    return TOAST_HEIGHT * index + OFFSET;
  };
</script>

<template>
  <transition-group
    :enter-active-class="enterActiveClass"
    :leave-active-class="leaveActiveClass"
  >
    <template
      v-for="(item, index) in store.items.values()"
      :key="item.distinctId"
    >
      <div
        :class="toastPositionClass"
        :style="{ top: getToastTopPosition(index) + 'px' }"
      >
        <Toast
          :test-id="`toast-${item.distinctId}`"
          v-bind="item"
          @close="() => store.removeItem(item)"
        />
      </div>
    </template>
  </transition-group>
</template>
