<script lang="ts" setup>
  import { InputWidthVariant, SelectProps } from '@tundr/ui-kit';
  import { useField } from 'vee-validate';
  import { toRef, toRefs } from 'vue';
  import Select from './Select.vue';

  export type SelectFieldProps = SelectProps & {
    rules?: (value: string) => string | boolean;
  };

  const props = withDefaults(defineProps<SelectFieldProps>(), {
    value: undefined,
    rules: undefined,
    searchable: true,
    clearOnSelect: true,
    closeOnSelect: true,
    showLabels: false,
    showNoOptions: true,
    showNoResults: true,
    allowEmpty: false,
    taggable: false,
    disabled: false,
    meta: undefined,
    internalSearch: true,
    width: InputWidthVariant.FULL,
  });

  const {
    name: inputName,
    label,
    rules,
    ...rest
  } = toRefs<SelectFieldProps>(props);
  // workaround to keep reactivity on rest value from toRefs
  const inputProps = toRef(rest);

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
  } = useField<string>(inputName.value, rules?.value, {
    label: label?.value,
  });
</script>

<template>
  <Select
    v-bind="inputProps"
    :error="errorMessage as string"
    :label="label"
    :meta="meta"
    :loading="loading"
    :modelValue="inputValue as string"
    :name="name"
    :openDirection="openDirection"
    :placeholder="placeholder"
    @blur="handleBlur"
    @update:modelValue="handleChange"
  />
</template>
