<script lang="ts" setup>
  import { useAuthStore } from '@tundr/auth';
  import { useLocale } from '@tundr/i18n';
  import { useModal } from '@tundr/modal';
  import { textEllipsisClass } from '@tundr/theme';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { FontIcon, Menu, MenuItem, PlacementOptions } from '@tundr/ui-kit';
  import { computed, onMounted } from 'vue';
  import { ENV_VARS } from '../../../core/config/env';
  import PrivacyModal from '../../../modules/app/serviceAgreements/components/PrivacyModal/PrivacyModal.vue';
  import { useServiceAgreements } from '../../../modules/app/serviceAgreements/composables/use-service-agreements';
  import { useLogout } from '../../composables/use-logout';
  import {
    menuTitleClass,
    userIconCollapsedClass,
    userNameBoxClass,
    userNameBoxExpandedClass,
    userNameClass,
  } from './UserMenu.css';

  type UserMenuProps = {
    collapsed: boolean;
  };

  const props = defineProps<UserMenuProps>();

  const { t } = useLocale('common');
  const authStore = useAuthStore();

  const { openModal } = useModal();

  const { privacyAcceptance, termsAcceptance } = useServiceAgreements();

  const { onLogout } = useLogout();

  onMounted(() => {
    if (!privacyAcceptance || !termsAcceptance) {
      openModal({
        component: PrivacyModal,
      });
    }
  });

  const triggerIcon = computed(() => {
    if (props.collapsed) {
      return UNICONS_ICONS.USER_CIRCLE;
    }
    return UNICONS_ICONS.ANGLE_DOWN;
  });

  const privacyDocumentUrl = ENV_VARS.serviceAgreements.privacy;
  const termsDocumentUrl = ENV_VARS.serviceAgreements.terms;

  const onDownloadPrivacyDocument = () => {
    window.open(privacyDocumentUrl, '_blank');
  };

  const onDownloadTermsDocument = () => {
    window.open(termsDocumentUrl, '_blank');
  };

  const userNameBoxClasses = computed(() => [
    userNameBoxClass,
    {
      [userNameBoxExpandedClass]: !props.collapsed,
    },
  ]);
</script>

<template>
  <div :class="userNameBoxClasses">
    <div v-if="!collapsed" :class="userNameClass">
      <FontIcon :iconName="UNICONS_ICONS.USER_CIRCLE" />
      <span :class="textEllipsisClass"
        >{{ authStore.user?.firstName }} {{ authStore.user?.lastName }}</span
      >
    </div>
    <Menu :placement="PlacementOptions.BOTTOM_END">
      <template #trigger>
        <FontIcon
          :test-id="'user-menu-trigger'"
          :title="
            collapsed
              ? `${authStore.user?.firstName} ${authStore.user?.lastName}`
              : ''
          "
          clickable
          :icon-name="triggerIcon"
          :class="collapsed && userIconCollapsedClass"
        />
      </template>
      <div>
        <div :class="menuTitleClass">
          {{ authStore.user?.firstName }} {{ authStore.user?.lastName }}
        </div>
        <MenuItem
          v-if="privacyDocumentUrl"
          :icon="ICONS.HEADQUARTERS"
          @click="onDownloadPrivacyDocument"
          test-id="menu-item-add-company"
          :label="t('serviceAgreements:privacy.short_link_text')"
        />
        <MenuItem
          v-if="termsDocumentUrl"
          :icon="ICONS.HEADQUARTERS"
          @click="onDownloadTermsDocument"
          test-id="menu-item-switch-company"
          :label="t('serviceAgreements:terms.short_link_text')"
        />
        <MenuItem
          :icon="ICONS.LOGOUT"
          @click="onLogout"
          test-id="menu-item-logout"
          :label="t('profile_menu.actions.logout.label')"
        />
      </div>
    </Menu>
  </div>
</template>
