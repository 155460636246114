<script setup lang="ts">
  import {
    confirmModalContainerClass,
    confirmModalContentClass,
    confirmModalContentDisclaimerClass,
    imageWrapperClass,
  } from './ConfirmModal.css.ts';
  import { useModal } from '../../composables/use-modal';
  import ModalSkeleton from '../ModalSkeleton/ModalSkeleton.vue';
  import ModalTitle from '../ModalTitle/ModalTitle.vue';
  import ModalActions from '../ModalActions/ModalActions.vue';
  import ModalContent from '../ModalContent/ModalContent.vue';
  import { useLocale } from '@tundr/i18n';
  import { Disclaimer, SvgImage } from '@tundr/ui-kit';

  const { t } = useLocale('common');

  const { closeModal, props, events, labels } = useModal();

  const emits = defineEmits<{
    confirm: [];
    cancel: [];
  }>();

  const onCancel = () => {
    emits('cancel');
    events?.onCancel && events.onCancel();
    closeModal();
  };

  const onConfirm = () => {
    emits('confirm');
    events!.onConfirm!();
    closeModal();
  };
</script>

<template>
  <ModalSkeleton :class="confirmModalContainerClass">
    <template #title>
      <ModalTitle>
        <div :class="imageWrapperClass" v-if="props?.image">
          <SvgImage
            showLoading
            :image="props!.image as string"
            fill="transparent"
            :width="80"
            :height="80"
          />
        </div>
        {{ props!.title }}
      </ModalTitle>
    </template>
    <ModalContent :class="confirmModalContentClass" v-if="props?.message">
      {{ props!.message }}
      <div
        v-if="props?.disclaimerTitle"
        :class="confirmModalContentDisclaimerClass"
      >
        <Disclaimer
          :title="props!.disclaimerTitle as string"
          :description="props!.disclaimerDescription as string"
        />
      </div>
    </ModalContent>
    <template #actions>
      <ModalActions
        :confirmLabel="labels?.confirm || t('actions.confirm')"
        :cancelLabel="labels?.cancel || t('actions.cancel')"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </template>
  </ModalSkeleton>
</template>
