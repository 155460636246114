<script setup lang="ts">
  import { computed, useAttrs } from 'vue';
  import { SidebarSectionHeader } from '../SidebarSectionHeader';
  import SidebarMenuItem from '../SidebarMenuItem/SidebarMenuItem.vue';
  import { SidebarDivider } from '../SidebarDivider';
  import { SidebarItemType } from '../Sidebar';

  export type SidebarItem = {
    type: SidebarItemType;
  };

  const props = defineProps<SidebarItem>();

  const attrs = useAttrs();

  const sidebarItemComponent = computed(() => {
    switch (props.type) {
      default:
      case SidebarItemType.MENU_ITEM:
        return SidebarMenuItem;
      case SidebarItemType.SECTION_HEADER:
        return SidebarSectionHeader;
      case SidebarItemType.DIVIDER:
        return SidebarDivider;
    }
  });
</script>

<template>
  <component :is="sidebarItemComponent" v-bind="attrs" />
</template>
