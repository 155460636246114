import { defineStore } from 'pinia';
import { ToastProps } from '../components/Toast/Toast.types.ts';
import { isRef, MaybeRef, ref } from 'vue';
import { ToastDuration } from '../utils/toast-duration.ts';
import { v4 as uuidv4 } from 'uuid';

type ToastStoreProps = ToastProps & { timeoutId: NodeJS.Timeout };

export type ToastPropsWithOptionalDistinctId = Omit<ToastProps, 'distinctId'> &
  Partial<Pick<ToastProps, 'distinctId'>>;

export const useToastStore = defineStore('toast', () => {
  const items = ref<Map<string, ToastStoreProps>>(new Map());

  const removeItem = (item: ToastStoreProps) => {
    items.value.delete(item.distinctId);
    clearTimeout(item.timeoutId);
  };

  const setItem = (
    item: MaybeRef<ToastPropsWithOptionalDistinctId>,
    timeout: ToastDuration = ToastDuration.STANDARD,
  ) => {
    const rawItem = isRef(item) ? item.value : item;
    const computedItem: ToastProps = {
      ...rawItem,
      distinctId: rawItem.distinctId ?? uuidv4(),
    };

    const hasToDistinct = items.value.get(computedItem.distinctId);
    if (hasToDistinct) return;

    const timeoutId = setTimeout(() => {
      removeItem({ ...computedItem, timeoutId });
    }, timeout);

    items.value.set(computedItem.distinctId, { ...computedItem, timeoutId });
  };

  return {
    items,
    removeItem,
    setItem,
  };
});
