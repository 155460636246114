import { Options, useRequest } from 'vue-request';
import { useHttpInstance } from './use-http-instance.ts';
import { HttpRequestFn, UseHttp, UseHttpOptions } from './use-http.types.ts';
import { HttpResponse } from '../types.ts';

export function useHttp<Response, Payload>(
  requestFn: HttpRequestFn<Response, Payload>,
  { initialParams, ...options }: UseHttpOptions<Response, Payload> = {},
): UseHttp<Response, Payload> {
  const httpFn = requestFn(useHttpInstance());
  const defaultParams: [Payload] | undefined = initialParams
    ? [initialParams]
    : undefined;
  return useRequest(httpFn, { ...options, defaultParams } as Options<
    HttpResponse<Response>,
    [Payload]
  >);
}
