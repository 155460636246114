<script lang="ts" setup>
  import { ICONS } from '@tundr/theme-assets/font-icon/icons.ts';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { useAttrs, useSlots, useTemplateRef, watchEffect } from 'vue';
  import FontIcon from '../../FontIcon/FontIcon.vue';
  import { Icon } from '../../Icon';
  import { InputWidthVariant } from '../InputWrapper/';
  import {
    clearInputIconClass,
    readonlyIconClass,
  } from '../InputWrapper/InputWrapper.css.ts';
  import InputWrapper from '../InputWrapper/InputWrapper.vue';
  import { TextInputProps } from './TextInput.types.ts';

  const props = withDefaults(defineProps<TextInputProps>(), {
    optionalLabel: false,
    disabled: false,
    type: 'text',
    placeholder: '',
    meta: undefined,
    width: InputWidthVariant.FULL,
  });

  const emit = defineEmits([
    'update:modelValue',
    'blur',
    'onEnter',
    'onTab',
    'focusout',
    'clear',
  ]);

  defineOptions({
    inheritAttrs: false,
  });
  const attrs = useAttrs();

  const slots = useSlots();

  const input = useTemplateRef<HTMLInputElement>('input');

  watchEffect(() => {
    if (props.autofocus && input.value) {
      input.value.focus();
    }
  });

  const onClear = () => {
    emit('update:modelValue', '');
    emit('clear');
  };
</script>

<template>
  <InputWrapper
    :id="id"
    :error="error"
    :warning="warning"
    :label="label"
    :loading="loading"
    :meta="meta"
    :width="width"
    :readonly="readonly"
    :optional-label="optionalLabel"
  >
    <template #inputLabel>
      <slot name="inputLabel"></slot>
    </template>
    <template #inputError>
      <slot name="inputError"></slot>
    </template>
    <template #inputWarning>
      <slot name="inputWarning"></slot>
    </template>
    <template #iconLeft>
      <slot name="iconLeft"></slot>
    </template>
    <template #iconRight>
      <slot v-if="slots.iconRight" name="iconRight"></slot>
      <FontIcon
        @click="onClear"
        v-if="!readonly && clearable && modelValue"
        clickable
        :icon-name="UNICONS_ICONS.TIMES"
        :class="clearInputIconClass"
      />
      <Icon
        v-else-if="readonly && !clearable"
        :class="readonlyIconClass"
        :icon-name="ICONS.LOCK"
      />
    </template>
    <template #default="slotProps">
      <input
        v-bind="attrs"
        ref="input"
        :id="id"
        :aria-required="!optionalLabel"
        :class="slotProps.slotClass"
        :data-test-id="testId"
        :disabled="disabled || loading"
        :name="name"
        :readonly="readonly"
        :aria-readonly="readonly"
        :placeholder="placeholder"
        :type="type"
        :autofocus="autofocus"
        :value="modelValue"
        @blur="emit('blur')"
        @focusout="emit('focusout')"
        @keydown.enter="emit('onEnter')"
        @keydown.tab="emit('onTab')"
        @input="
          emit('update:modelValue', ($event.target as HTMLInputElement).value)
        "
      />
    </template>
  </InputWrapper>
</template>
