import { App, ref } from 'vue';

const appContainer = ref<App>();

export function setAppContext(app: App) {
  appContainer.value = app;
}

export function runInsideAppContext<T>(callback: () => T): T {
  if (!appContainer.value) {
    throw new Error('Vue app not initialized yet!');
  }
  return appContainer.value.runWithContext(callback);
}
