<script setup lang="ts">
  import {
    closeModalButtonClass,
    modalBackgroundClass,
    modalContainerClass,
  } from './Modal.css';
  import { useModalStore } from '../../store/modal.store';
  import { computed, onMounted, onUnmounted } from 'vue';
  import { Icon, useFocusTrap } from '@tundr/ui-kit';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons.ts';

  const modalStore = useModalStore();

  const { trapRef } = useFocusTrap(
    modalStore.modalState.config?.firstSelectionOnLastItem,
  );

  function keydownListener(event: KeyboardEvent) {
    if (event.key === 'Escape' && !modalStore.modalState.config?.blocking)
      modalStore.closeModal();
  }

  onMounted(() => {
    document.addEventListener('keydown', keydownListener);
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', keydownListener);
  });

  const closeOnBackdrop = () => {
    if (!modalStore.modalState.config?.blocking) modalStore.closeModal();
  };

  const isVisible = computed<boolean>(() => {
    return !!modalStore.modalState.component;
  });
</script>

<template>
  <Transition name="modal-fade">
    <div
      v-if="isVisible"
      :class="modalBackgroundClass"
      @click.self="closeOnBackdrop"
    >
      <dialog
        ref="trapRef"
        :class="modalContainerClass"
        aria-modal="true"
        tabindex="-1"
      >
        <Icon
          clickable
          :class="closeModalButtonClass"
          :iconName="ICONS.CLOSE"
          @click="modalStore.closeModal"
        />
        <component
          :is="modalStore.modalState.component"
          v-bind="modalStore.modalState.props"
        />
      </dialog>
    </div>
  </Transition>
</template>

<style scoped>
  .modal-fade-enter-from,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: 0.35s ease all;
  }
</style>
