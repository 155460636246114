export enum ChipVariant {
  BRAND = 'brand',
  BRAND_CONTRAST = 'brand-contrast',
  LIGHT = 'light',
  DARK = 'dark',
  INFO = 'info',
  TERTIARY = 'tertiary',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}
