import { hrRoutesDictionary } from './hr-routes.dictionary.ts';
import { RouteRecordRaw } from 'vue-router';

export const hrRoutesConfig: RouteRecordRaw = {
  ...hrRoutesDictionary.hr.root,
  redirect: { name: hrRoutesDictionary.hr.list.name },
  children: [
    {
      ...hrRoutesDictionary.hr.list,
      component: () => import('../pages/List/HrListPage.vue'),
    },
    {
      ...hrRoutesDictionary.hr.editAnagraphic,
      component: () =>
        import('../pages/Edit/EditAnagraphicData/EditAnagraphicData.vue'),
      props: true,
    },
    {
      ...hrRoutesDictionary.hr.editAdditionalData,
      component: () =>
        import('../pages/Edit/EditAdditionalData/EditAdditionalData.vue'),
      props: true,
    },
  ],
};
