import { subYears } from 'date-fns';

export const VALIDATION_I18N_PATH = 'common.validation';
export const GENERIC_ERROR_RULE = 'generic';

const MIN_VALID_AGE = 4;
const MAX_VALID_AGE = 100;
const SUGGESTED_STARTING_AGE = 18;

export const COMMON_STARTING_DATE = subYears(
  new Date(),
  SUGGESTED_STARTING_AGE,
);
export const OLDEST_VALID_DATE = subYears(new Date(), MAX_VALID_AGE);
export const YOUNGEST_VALID_DATE = subYears(new Date(), MIN_VALID_AGE);
