import { DefineNumberFormat } from 'vue-i18n';
import { I18N_AVAILABLE_LOCALES } from './locales.ts';

export const BASE_CURRENCY_DIVISION = 100;

export enum NumberFormatType {
  INTEGER = 'integer',
  ALWAYS_DECIMAL = 'alwaysDecimal',
  MAYBE_DECIMAL = 'maybeDecimal',
  CURRENCY = 'currency',
  INTEGER_PERCENTAGE = 'integerPercentage',
  DECIMAL_PERCENTAGE = 'decimalPercentage',
}

const numberFormatsConfig: DefineNumberFormat = {
  [NumberFormatType.INTEGER]: {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  [NumberFormatType.ALWAYS_DECIMAL]: {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  [NumberFormatType.MAYBE_DECIMAL]: {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  },
  [NumberFormatType.CURRENCY]: {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  [NumberFormatType.INTEGER_PERCENTAGE]: {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  },
  [NumberFormatType.DECIMAL_PERCENTAGE]: {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
};

export function generateNumberFormatsConfig(
  locales: typeof I18N_AVAILABLE_LOCALES,
): Record<string, DefineNumberFormat> {
  return locales.reduce(
    (acc, locale) => {
      acc[locale] = numberFormatsConfig;
      return acc;
    },
    {} as Record<string, DefineNumberFormat>,
  );
}
