<script setup lang="ts">
  import { ComponentCommonTypes } from '../utils/commonTypes';
  import { clickableClass, disabledClass } from '@tundr/theme';
  import { computed, ref } from 'vue';
  import { getIconName } from '../utils/getIconName';
  import InlineSvg from 'vue-inline-svg';
  import Skeleton from '../Skeleton/Skeleton.vue';
  import { SkeletonVariants } from '../Skeleton/Skeleton.types';

  export type SvgImageProps = ComponentCommonTypes & {
    image: string;
    fill?: string;
    width?: number;
    height?: number;
    disabled?: boolean;
    clickable?: boolean;
    showLoading?: boolean;
  };

  const props = withDefaults(defineProps<SvgImageProps>(), {
    fill: 'white',
    width: 24,
    height: 24,
    disabled: false,
    clickable: false,
    showLoading: false,
  });
  const emits = defineEmits(['click']);

  const imgLoaded = ref<boolean>(false);

  const iconClasses = computed(() => [
    {
      [disabledClass]: props.disabled,
      [clickableClass]: props.clickable,
    },
  ]);

  const onImgLoaded = () => {
    imgLoaded.value = true;
  };

  const getMaxBeetweenWidthAndHeight = computed(() => {
    return Math.max(props.width, props.height);
  });

  defineOptions({
    inheritAttrs: false,
  });
</script>

<template>
  <Skeleton
    v-if="showLoading && !imgLoaded"
    :width="getMaxBeetweenWidthAndHeight"
    :height="getMaxBeetweenWidthAndHeight"
    :variant="SkeletonVariants.CIRCLE"
  />
  <InlineSvg
    :id="id"
    :title="title !== undefined ? title : getIconName(image)"
    :class="iconClasses"
    :src="image"
    :width="width"
    :height="height"
    :fill="fill"
    @loaded="onImgLoaded"
    @click="emits('click')"
    @error="console.log($event)"
    v-bind="$attrs"
  />
</template>
