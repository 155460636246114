import { inject } from 'vue';
import { HTTP_TOKEN } from '../config.ts';
import { HttpInstance } from '../types.ts';

export const useHttpInstance = (): HttpInstance => {
  const http = inject<HttpInstance>(HTTP_TOKEN);
  if (!http) {
    throw new Error(
      'Http library not found, ensure you are operating under Vue context',
    );
  }
  return http;
};
