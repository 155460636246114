import { DefineDateTimeFormat } from 'vue-i18n';
import { I18N_AVAILABLE_LOCALES } from './locales.ts';

export enum DatetimeFormatType {
  SHORT_DATE = 'shortDate',
  DATETIME = 'datetime',
  /**
   * @deprecated
   */
  DATE_ONLY_ISO = 'dateOnlyISO',
}

export const datetimeFormatsConfig: DefineDateTimeFormat = {
  [DatetimeFormatType.SHORT_DATE]: {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  },
  [DatetimeFormatType.DATETIME]: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
};

export function generateDatetimeFormatsConfig(
  locales: typeof I18N_AVAILABLE_LOCALES,
): Record<string, DefineDateTimeFormat> {
  return locales.reduce(
    (acc, locale) => {
      acc[locale] = datetimeFormatsConfig;
      return acc;
    },
    {} as Record<string, DefineDateTimeFormat>,
  );
}
