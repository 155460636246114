<script lang="ts" setup>
  import { toRef, toRefs } from 'vue';
  import PhoneInput, { PhoneInputProps } from './PhoneInput.vue';
  import { useField } from 'vee-validate';
  import { InputWidthVariant } from '../InputWrapper/';

  export type PhoneInputFieldProps = PhoneInputProps & {
    rules?: (value: string) => string | boolean;
  };

  const props = withDefaults(defineProps<PhoneInputFieldProps>(), {
    value: undefined,
    rules: undefined,
    width: InputWidthVariant.FULL,
  });

  const {
    name: inputName,
    label,
    rules,
    ...rest
  } = toRefs<PhoneInputFieldProps>(props);
  // workaround to keep reactivity on rest value from toRefs
  const inputProps = toRef(rest);

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
  } = useField<string>(inputName.value, rules?.value, {
    label: label?.value,
  });

  const updateValue = (value: string) => {
    handleChange(value, true);
  };
</script>

<template>
  <PhoneInput
    v-bind="inputProps"
    :error="errorMessage as string"
    :label="label"
    :meta="meta"
    :loading="loading"
    :autofocus="autofocus"
    :modelValue="inputValue"
    :name="name"
    :inputType="inputType"
    :placeholder="placeholder"
    :width="width"
    @blur="handleBlur"
    @update:modelValue="updateValue"
  >
    <template #iconRight>
      <slot name="iconRight"></slot>
    </template>
  </PhoneInput>
</template>
