<script setup lang="ts">
  import {
    bradCrumbItemClickableTitleClass,
    bradCrumbItemContainerClass,
    bradCrumbItemTitleClass,
    itemDividerClass,
  } from './BreadCrumbItem.css';
  import { computed } from 'vue';
  import { FontIcon } from '@tundr/ui-kit';
  import { BreadCrumbItemProps } from './BreadCrumbItem.types';

  const props = defineProps<BreadCrumbItemProps & { isLast: boolean }>();

  const itemClasses = computed(() => [
    bradCrumbItemTitleClass,
    { [bradCrumbItemClickableTitleClass]: !!props.to || !!props.onClick },
  ]);
</script>

<template>
  <div :class="bradCrumbItemContainerClass" @click="onClick ? onClick() : null">
    <FontIcon v-if="iconName" :iconName="iconName" />
    <router-link v-if="to" :class="itemClasses" :to="to">{{
      title
    }}</router-link>
    <span v-else :class="itemClasses">{{ title }}</span>
    <span :class="itemDividerClass" v-if="!isLast">/</span>
  </div>
</template>
