<script lang="ts" setup>
  import { ComponentCommonTypes } from '../../utils/commonTypes';
  import { FieldMeta } from 'vee-validate';
  import { InputWidthVariant } from '../InputWrapper';
  import { VueTelInput, VueTelInputCountryOption } from 'vue-tel-input';
  import 'vue-tel-input/dist/vue-tel-input.css';
  import {
    computed,
    ref,
    useAttrs,
    useSlots,
    useTemplateRef,
    watchEffect,
  } from 'vue';
  import {
    phoneInputClass,
    phoneInputLoaderClass,
    phoneInputWrapperClass,
  } from './PhoneInput.css.ts';
  import InputWrapper from '../InputWrapper/InputWrapper.vue';
  import Icon from '../../Icon/Icon.vue';
  import { FixedVueTelInputResponse } from './PhoneInput.types.ts';
  import {
    readonlyIconClass,
    rightSlotClass,
  } from '../InputWrapper/InputWrapper.css.ts';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons.ts';

  export type PhoneInputProps = ComponentCommonTypes & {
    modelValue?: string;
    label?: string;
    name: string;
    placeholder?: string;
    error?: string;
    disabled?: boolean;
    readonly?: boolean;
    autofocus?: boolean;
    required?: boolean;
    disableAutoFormat?: boolean;
    allowAnyCharacters?: boolean;
    loading?: boolean;
    meta?: FieldMeta<string>;
    optionalLabel?: boolean;
    width?: InputWidthVariant;
    defaultCountry?: string;
    inputType?: string;
  };

  const props = withDefaults(defineProps<PhoneInputProps>(), {
    placeholder: '',
    disableAutoFormat: false,
    allowAnyCharacters: false,
    optionalLabel: false,
    disabled: false,
    meta: undefined,
    inputType: 'tel',
    width: InputWidthVariant.FULL,
    defaultCountry: 'it',
  });

  const emit = defineEmits<{
    'update:modelValue': [value: string, phoneObj: FixedVueTelInputResponse];
    validate: [phoneObj: FixedVueTelInputResponse];
    'country-changed': [countryObject: VueTelInputCountryOption];
    blur: [ev?: Event];
    focus: [ev?: Event];
    space: [ev?: Event];
    enter: [ev?: Event];
    open: [ev?: Event];
    close: [ev?: Event];
  }>();

  defineOptions({
    inheritAttrs: false,
  });
  const attrs = useAttrs();

  const slots = useSlots();

  const inputOptions = computed(() => ({
    autocomplete: true,
    autofocus: props.autofocus,
    id: 'phone-input',
    name: props.name,
    showDialCode: false,
    placeholder: props.placeholder,
    required: props.required,
    readonly: false,
    type: props.inputType,
    styleClasses: [phoneInputClass, props.loading && phoneInputLoaderClass],
  }));

  const dropdownOptions = computed(() => ({
    disabled: props.disabled || props.loading || props.readonly,
    showDialCodeInList: true,
    showDialCodeInSelection: true,
    showFlags: true,
    showSearchBox: true, // not working in v6.0.1
  }));

  const internalModel = ref<string | undefined>(props.modelValue);

  const onInput = (number: string, phoneObj: FixedVueTelInputResponse) => {
    internalModel.value = number;
    phoneObj.number && emit('update:modelValue', phoneObj.number, phoneObj);
  };

  const onValidate = (phoneObj: FixedVueTelInputResponse) => {
    emit('validate', phoneObj);
  };
  const onCountryChanged = (countryObject: VueTelInputCountryOption) => {
    emit('country-changed', countryObject);
  };

  const input = useTemplateRef<HTMLInputElement>('input');

  watchEffect(() => {
    if (props.autofocus && input.value) {
      input.value.focus();
    }
  });
</script>

<template>
  <InputWrapper
    :id="id"
    :error="error"
    :label="label"
    :meta="meta"
    :width="width"
    :optional-label="optionalLabel"
  >
    <template #inputLabel>
      <slot name="inputLabel"></slot>
    </template>
    <template #inputError>
      <slot name="inputError"></slot>
    </template>
    <template #default="slotProps">
      <VueTelInput
        id="phone-input"
        ref="input"
        :styleClasses="[attrs.class, phoneInputWrapperClass]"
        :autoFormat="!disableAutoFormat"
        :validCharactersOnly="!allowAnyCharacters"
        :defaultCountry="defaultCountry"
        :disabled="disabled || loading || readonly"
        :inputOptions="inputOptions"
        :aria-readonly="readonly"
        :dropdownOptions="dropdownOptions"
        :autofocus="autofocus"
        :modelValue="internalModel"
        mode="international"
        @on-input="onInput"
        @validate="onValidate"
        @countryChanged="onCountryChanged"
        @blur="emit('blur')"
        @focus="emit('focus')"
        @space="emit('space')"
        @enter="emit('enter')"
        @open="emit('open')"
        @close="emit('close')"
        v-bind="slotProps"
      >
        <template #icon-right>
          <div :class="rightSlotClass">
            <slot v-if="slots.iconRight" name="iconRight"></slot>
            <Icon
              v-else-if="readonly"
              :class="readonlyIconClass"
              :icon-name="ICONS.LOCK"
            />
          </div>
        </template>
      </VueTelInput>
    </template>
  </InputWrapper>
</template>
