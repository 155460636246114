<script setup lang="ts">
  import { loaderClass } from './Loader.css';
  import { ComponentCommonTypes } from '../utils/commonTypes';
  import SvgImage, { SvgImageProps } from '../SvgImage/SvgImage.vue';
  import loader from '@tundr/theme-assets/icons/loader.svg';
  import { LoaderSize } from './Loader.utils';

  export type LoaderProps = ComponentCommonTypes &
    Pick<SvgImageProps, 'fill'> & {
      size?: LoaderSize;
    };

  const sizesMap = {
    [LoaderSize.mini]: {
      width: 15,
      height: 15,
    },
    [LoaderSize.small]: {
      width: 22,
      height: 22,
    },
    [LoaderSize.medium]: {
      width: 40,
      height: 40,
    },
    [LoaderSize.large]: {
      width: 60,
      height: 60,
    },
    [LoaderSize.extra]: {
      width: 100,
      height: 100,
    },
  };

  const props = withDefaults(defineProps<LoaderProps>(), {
    size: LoaderSize.medium,
  });
</script>

<template>
  <div :class="loaderClass">
    <SvgImage
      :id="id"
      :title="title || 'Loading...'"
      :fill="props.fill || 'black'"
      :image="loader"
      :width="sizesMap[props.size]?.width"
      :height="sizesMap[props.size]?.height"
    />
  </div>
</template>
