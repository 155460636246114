import { EntityBaseFields } from '@tundr/http';

export enum WelfarePlan {
  STANDARD = 'STANDARD',
  COMPLETE = 'COMPLETE',
}

export type LoginUser = EntityBaseFields & {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthdate: string;
};

export type LoginCompany = {
  id: string;
  name: string;
  currentWelfarePlan: WelfarePlan;
};

export type AuthData = {
  user: LoginUser;
  companies: LoginCompany[];
};
