import { defineStore } from 'pinia';
import { Component, shallowRef } from 'vue';
import { ModalConfig } from '../components/Modal';

export interface ModalStoreProps {
  component: null | Component;
  props?: Record<string, unknown>;
  config?: ModalConfig;
  events?: {
    onClose?: () => void;
    onConfirm?: () => void;
    onCancel?: () => void;
  };
  labels?: {
    confirm?: string;
    cancel?: string;
  };
}

const initialState = {
  component: null,
  props: {},
  config: { blocking: true, firstSelectionOnLastItem: true },
};

export const useModalStore = defineStore('modal', () => {
  const modalState = shallowRef<ModalStoreProps>(initialState);

  const openModal = ({
    component,
    props = {},
    config = { blocking: true, firstSelectionOnLastItem: true },
    events = {},
    labels = {},
  }: ModalStoreProps) => {
    modalState.value = {
      component,
      props,
      config,
      events,
      labels,
    };
  };

  const closeModal = () => {
    if (modalState.value.events?.onClose) {
      modalState.value.events.onClose();
    }
    modalState.value = initialState;
  };

  return { modalState, openModal, closeModal };
});
