import { useI18n } from 'vue-i18n';
import { DatetimeFormatType } from './config/datetime-formats.config.ts';
import { toISODateOnlyString } from './utils/dates.utils.ts';
import { LocaleType } from './config/locales.ts';
import { DateLike } from './date.types.ts';
import { isValid } from 'date-fns';
import {
  BASE_CURRENCY_DIVISION,
  NumberFormatType,
} from './config/number-formats.config.ts';
import { computed } from 'vue';

type Tail<T extends unknown[]> = T extends [unknown, ...infer U] ? U : never;

// Extract all the arguments except the first
type ArgumentsExceptFirst<T> = T extends (...args: infer Args) => unknown
  ? Tail<Args>
  : never;

export const italianDateFormat = 'dd/MM/yyyy';

const localeDateStringParsers: Record<LocaleType, string> = {
  it: italianDateFormat,
};

function isValidDate(date: Date | null): date is Date {
  return isValid(date);
}

export const useLocale = (namespace?: string) => {
  const { t: oldT, d: oldD, n: oldN, ...rest } = useI18n();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tWithNamespace: typeof oldT = (
    key: string,
    ...args: ArgumentsExceptFirst<typeof oldT>
  ) => {
    let finalKey = key;
    let finalNamespace = namespace;

    // if the key contains a colon, we take the value before as a namespace and extract the key
    if (key.includes(':')) {
      finalNamespace = key.split(':')[0];
      finalKey = key.split(':')[1];
    }
    const tKey = finalNamespace ? `${finalNamespace}.${finalKey}` : finalKey;
    return oldT(tKey, ...args);
  };

  const currentLanguageDateFormat = computed(() => {
    return localeDateStringParsers[rest.locale.value as LocaleType];
  });

  const augmentedD = (
    date: DateLike,
    formatType: DatetimeFormatType,
  ): string => {
    let parsedDate: Date | null = null;
    if (date instanceof Date) {
      parsedDate = date;
    }
    if (typeof date === 'number') {
      parsedDate = new Date(date);
    }
    if (
      typeof date === 'string' &&
      formatType !== DatetimeFormatType.DATE_ONLY_ISO
    ) {
      parsedDate = new Date(date);
    }

    if (!isValidDate(parsedDate)) {
      throw new Error(`Invalid date provided: ${date}`);
    }
    if (formatType === DatetimeFormatType.DATE_ONLY_ISO) {
      return toISODateOnlyString(parsedDate);
    }
    return oldD(parsedDate, formatType);
  };

  const augmentedN = (number: number, formatType: NumberFormatType): string => {
    let finalNumber = number;
    if (formatType === NumberFormatType.CURRENCY) {
      finalNumber = number / BASE_CURRENCY_DIVISION;
    }
    return oldN(finalNumber, formatType);
  };

  return {
    t: tWithNamespace,
    d: augmentedD,
    n: augmentedN,
    currentLanguageDateFormat,
    ...rest,
  };
};
