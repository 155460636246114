export enum BANK_PROVIDER_URL_PARAMS {
  STATUS = 'status',
  METADATA = 'metadata',
  ERROR_CODE = 'errorCode',
}

export enum LoginRedirectStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export enum BANK_PROVIDER_ERROR_CODES {
  INCOMPATIBLE_LOGGED_USER_DATA = 'INCOMPATIBLE_LOGGED_USER_DATA',
  BANK_PROVIDER_REQUIRED_LOGIN = 'BANK_PROVIDER_REQUIRED_LOGIN',
  BLOCKED_WINDOW = 'BLOCKED_WINDOW',
  CLOSE_WINDOW_WARNING = 'CLOSE_WINDOW_WARNING',
  SWAN_GENERIC_ERROR = 'SWAN_GENERIC_ERROR',
  USER_NOT_VERIFIED = 'USER_NOT_VERIFIED',
}

export type BankProviderUser = {
  firstName: string;
  lastName: string;
  phonenumber: string;
  birthdate: string;
};

export type BankProviderMetadata = {
  expected: BankProviderUser;
  received: BankProviderUser;
};
