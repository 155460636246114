<script setup lang="ts">
  import { computed, useAttrs, useTemplateRef } from 'vue';
  import { FontIcon, Icon } from '@tundr/ui-kit';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons.ts';
  import {
    closeIconClass,
    disableShadowClass,
    statusIconBackgroundClass,
    statusIconClass,
    statusIconWrapperClass,
    toastBaseClass,
    toastContentClass,
    toastDescriptionClass,
    toastFullWidthClass,
    toastMessageClass,
    toastVariants,
  } from './Toast.css.ts';
  import { textEllipsisClass, ToastVariant } from '@tundr/theme';
  import { onClickOutside } from '@vueuse/core';
  import { ToastProps } from './Toast.types.ts';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';

  const containerRef = useTemplateRef('containerRef');

  const props = withDefaults(defineProps<ToastProps>(), {
    disableClose: false,
    disableDescriptionEllipsis: true,
    distinctId: '',
    disableShadow: false,
  });

  const attrs = useAttrs();
  const emits = defineEmits(['close', 'clickOutside']);

  onClickOutside(containerRef, () => {
    emits('clickOutside');
  });

  const toastClasses = computed(() => [
    attrs.class,
    toastBaseClass,
    toastVariants[props.variant],
    {
      [disableShadowClass]: props.disableShadow,
      [toastFullWidthClass]: props.fullWidth,
    },
  ]);

  const statusIconClasses = computed(() => [
    statusIconWrapperClass,
    statusIconBackgroundClass[props.variant],
  ]);

  const descriptionClasses = computed(() => [
    toastDescriptionClass,
    {
      [textEllipsisClass]: !props.disableDescriptionEllipsis,
    },
  ]);

  const iconByVariant = computed(() => {
    const iconsMap = {
      [ToastVariant.ERROR]: UNICONS_ICONS.TIMES_CIRCLE,
      [ToastVariant.WARNING]: UNICONS_ICONS.EXCLAMATION_TRIANGLE,
      [ToastVariant.SUCCESS]: UNICONS_ICONS.CHECK_CIRCLE,
      [ToastVariant.INFO]: UNICONS_ICONS.INFO_CIRCLE,
    };
    return iconsMap[props.variant || ToastVariant.INFO];
  });
</script>

<template>
  <div
    ref="containerRef"
    :class="toastClasses"
    :id="id"
    :data-distinct-id="distinctId"
    :data-test-id="testId"
    :title="title"
  >
    <Icon
      :class="closeIconClass"
      v-if="!disableClose"
      clickable
      @click="emits('close')"
      :icon-name="ICONS.CLOSE"
    />
    <slot name="icon">
      <div :class="statusIconClasses">
        <FontIcon :class="statusIconClass" :iconName="icon || iconByVariant" />
      </div>
    </slot>
    <slot name="content">
      <div :class="toastContentClass">
        <div :class="toastMessageClass">
          <slot name="message">{{ message }}</slot>
        </div>
        <div v-if="description" :class="descriptionClasses">
          <slot name="description">{{ description }}</slot>
        </div>
      </div>
    </slot>
  </div>
</template>
