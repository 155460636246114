<script lang="ts" setup>
  import { useLocale } from '@tundr/i18n';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { useScroll } from '@vueuse/core';
  import { computed, ref } from 'vue';
  import FontIcon from '../../../FontIcon/FontIcon.vue';
  import Tooltip from '../../../Tooltip/Tooltip.vue';
  import {
    scrollToTopClass,
    scrollToTopIconClass,
  } from './ScrollTopButton.css';

  const { t } = useLocale('common');

  const el = ref<HTMLElement | null>(null);
  const { y } = useScroll(el, {
    behavior: 'smooth',
  });

  const showScrollToTop = computed(() => {
    return y.value > 300 || !top;
  });

  const onScrollToTop = () => {
    y.value = 0;
  };
</script>

<template>
  <Tooltip :label="t('actions.scroll_to_top')">
    <Transition name="fade" :appear="true">
      <div
        :class="scrollToTopClass"
        v-if="showScrollToTop"
        @click="onScrollToTop"
      >
        <FontIcon
          :icon-name="UNICONS_ICONS.ARROW_UP"
          :class="scrollToTopIconClass"
        />
      </div>
    </Transition>
  </Tooltip>
</template>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
