<script setup lang="ts">
  import { clickableClass } from '@tundr/theme';
  import { TundrLogoVariant } from './TundrLogo.types';
  import whiteLogo from '@tundr/theme-assets/images/tundr-logo-white.png?url';
  import sidebarLogo from '@tundr/theme-assets/images/tundr-logo-sidebar.png?url';
  import sidebarLogoBlack from '@tundr/theme-assets/images/tundr-logo-sidebar-black.png?url';
  import blackLogo from '@tundr/theme-assets/images/tundr-logo-black.svg?url';
  import { computed } from 'vue';
  import { logoClass, logoWidthClass } from './TundrLogo.css.ts';

  export type LogoProps = {
    variant?: TundrLogoVariant;
    clickable?: boolean;
  };

  const props = withDefaults(defineProps<LogoProps>(), {
    variant: TundrLogoVariant.LIGHT,
    clickable: false,
  });

  const logoImg = computed(() => {
    switch (props.variant) {
      default:
      case TundrLogoVariant.LIGHT:
        return whiteLogo;
      case TundrLogoVariant.DARK:
        return blackLogo;
      case TundrLogoVariant.SMALL:
        return sidebarLogo;
      case TundrLogoVariant.SMALL_DARK:
        return sidebarLogoBlack;
    }
  });

  const computedClass = computed(() => {
    return [
      logoClass,
      {
        [logoWidthClass[props.variant]]: true,
        [clickableClass]: props.clickable,
      },
    ];
  });
</script>

<template>
  <img :class="computedClass" :src="logoImg" alt="Tundr" v-bind="$attrs" />
</template>
