<script lang="ts" setup>
  import { toRef, toRefs } from 'vue';
  import { useField } from 'vee-validate';
  import PasswordInput from './PasswordInput.vue';
  import { TextInputFieldProps } from '../TextInput/TextInputField.vue';
  import { InputWidthVariant } from '../InputWrapper/';

  const props = withDefaults(defineProps<Omit<TextInputFieldProps, 'type'>>(), {
    label: 'Password',
    rules: undefined,
    width: InputWidthVariant.FULL,
  });

  const {
    name: inputName,
    label,
    rules,
    ...rest
  } = toRefs<TextInputFieldProps>(props);

  const inputProps = toRef(rest);

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    handleChange,
    meta,
  } = useField<string>(inputName!.value, rules?.value, {
    label: label?.value,
  });
</script>

<template>
  <PasswordInput
    v-bind="inputProps"
    :error="errorMessage as string"
    :label="label"
    :meta="meta"
    :modelValue="inputValue as string"
    :name="name"
    :placeholder="placeholder"
    @blur="handleBlur"
    @update:modelValue="handleChange"
  >
    <template #iconRight>
      <slot name="iconRight"></slot>
    </template>
  </PasswordInput>
</template>
