<script setup lang="ts">
  import {
    Tooltip as ArkTooltip,
    TooltipContent,
    TooltipPositioner,
    TooltipTrigger,
  } from '@ark-ui/vue';
  import { tooltipContentClass, tooltipPositionerClass } from './Tooltip.css';
  import { computed, useSlots } from 'vue';
  import { GLOBAL_OVERLAYS_TARGET } from '../config/global-overlays-target';
  import { PlacementOptions } from '../utils/placement';

  export type TooltipProps = {
    label?: string;
    openDelay?: number;
    interactive?: boolean;
    placement?: PlacementOptions;
  };

  withDefaults(defineProps<TooltipProps>(), {
    showArrow: false,
    interactive: true,
    openDelay: 100,
    placement: PlacementOptions.TOP,
  });

  const slots = useSlots();
  const component = computed(() => slots.default!()[0]);

  defineOptions({
    inheritAttrs: false,
  });
</script>

<template>
  <ArkTooltip
    v-if="label"
    :interactive="interactive"
    :openDelay="openDelay"
    :positioning="{ placement: placement }"
  >
    <TooltipTrigger>
      <component :is="component" v-bind="$props" />
    </TooltipTrigger>

    <Teleport :to="`.${GLOBAL_OVERLAYS_TARGET}`">
      <TooltipPositioner :class="tooltipPositionerClass">
        <TooltipContent :class="tooltipContentClass" v-bind="$attrs">
          {{ label }}
        </TooltipContent>
      </TooltipPositioner>
    </Teleport>
  </ArkTooltip>
  <slot v-else v-bind="$props" />
</template>
