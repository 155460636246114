import { runInsideAppContext } from '@tundr/app-context';
import {
  handleHttpErrors,
  HttpResponseError,
  HttpValidationErrorData,
  showValidationErrorNotification,
} from '@tundr/http';
import { useModal } from '@tundr/modal';
import { useToast } from '@tundr/toast';
import { I18nTFunction } from '@tundr/validation';
import { defineAsyncComponent, Ref } from 'vue';
import { useBankProviderLogin } from '../../api/bank-provider/bank-provider.api';
import {
  BANK_PROVIDER_ERROR_CODES,
  BANK_PROVIDER_URL_PARAMS,
  LoginRedirectStatus,
  BankProviderMetadata,
} from './bank-provider-consent-manager.types';

const CHECK_URL_POLLING_DELAY = 300;
const POPUP_WIDTH = 800;
const POPUP_HEIGHT = 900;

const SwanIncompatibleUserModal = defineAsyncComponent(
  () =>
    import(
      '../../../shared/components/SwanIncompatibleUserModal/SwanIncompatibleUserModal.vue'
    ),
);

const NotVerifiedUserModal = defineAsyncComponent(
  () =>
    import(
      '../../../shared/components/NotVerifiedUserModal/NotVerifiedUserModal.vue'
    ),
);

type RedirectUrlError<T = undefined> = {
  errorCode: BANK_PROVIDER_ERROR_CODES;
  metadata?: T;
};

export const observeWindow =
  (timeoutRef: Ref<ReturnType<typeof setTimeout> | null>) =>
  (url?: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (url) {
        const returnPage = window.open(
          url,
          '',
          `toolbar=0,status=0,width=${POPUP_WIDTH},height=${POPUP_HEIGHT},scrollbars=1,resizable=1,left=0,top=0`,
        );

        const checkUrl = () => {
          if (returnPage === null) {
            reject({ errorCode: BANK_PROVIDER_ERROR_CODES.BLOCKED_WINDOW });
            return;
          }
          if (!returnPage?.window) {
            reject({
              errorCode: BANK_PROVIDER_ERROR_CODES.CLOSE_WINDOW_WARNING,
            });
            return;
          }
          try {
            const url = new URL(returnPage!.window.location.href);
            const status = url.searchParams.get(
              BANK_PROVIDER_URL_PARAMS.STATUS,
            );
            if (!status) {
              timeoutRef.value = setTimeout(() => {
                checkUrl();
              }, CHECK_URL_POLLING_DELAY);
              return;
            }
            returnPage!.close();
            if (status === LoginRedirectStatus.SUCCESS) {
              resolve();
            } else {
              const errorCode = url.searchParams.get(
                BANK_PROVIDER_URL_PARAMS.ERROR_CODE,
              );
              if (errorCode) {
                const metadata = JSON.parse(
                  url.searchParams.get(BANK_PROVIDER_URL_PARAMS.METADATA)!,
                );
                reject({ errorCode, metadata });
                return;
              } else {
                reject({
                  errorCode: BANK_PROVIDER_ERROR_CODES.SWAN_GENERIC_ERROR,
                });
                return;
              }
            }
          } catch (error) {
            timeoutRef.value = setTimeout(() => {
              checkUrl();
            }, CHECK_URL_POLLING_DELAY);
          }
        };

        timeoutRef.value = setTimeout(() => {
          checkUrl();
        }, CHECK_URL_POLLING_DELAY);
      }
    });
  };

type HandleConsentErrorPayload = {
  timeoutInstance: Ref<ReturnType<typeof setTimeout> | null>;
  url: string;
  t: I18nTFunction;
};

export const handleBankProviderConsentError: (
  payload: HandleConsentErrorPayload,
) => Promise<void> = async ({ url, timeoutInstance, t }) => {
  const { showError } = runInsideAppContext(useToast);
  try {
    const observeWindowConfigured = observeWindow(timeoutInstance);
    await observeWindowConfigured(url);
  } catch (error) {
    const { errorCode } = error as RedirectUrlError;
    showError({
      message: t(`bankProvider:errors.${errorCode}.title`),
      description: t(`bankProvider:errors.${errorCode}.description`),
    });
    throw error;
  }
};

type HandleLoginErrorPayload = {
  onSuccess: () => void;
  timeoutInstance: Ref<ReturnType<typeof setTimeout> | null>;
  httpError: HttpResponseError<HttpValidationErrorData>;
  t: I18nTFunction;
};

export const handleBankProviderLoginError: (
  payload: HandleLoginErrorPayload,
) => boolean = ({ onSuccess, httpError, timeoutInstance, t }) => {
  const { showError, showWarning } = runInsideAppContext(useToast);
  const { openModal } = runInsideAppContext(useModal);

  const { run: bankProviderLogin } = runInsideAppContext(() =>
    useBankProviderLogin({
      onSuccess: async (res) => {
        try {
          const observeWindowConfigured = observeWindow(timeoutInstance);
          await observeWindowConfigured(res.data.loginUri);
          onSuccess();
        } catch (error) {
          const bankProviderLoginError =
            error as RedirectUrlError<BankProviderMetadata>;
          if (
            bankProviderLoginError.errorCode ===
            BANK_PROVIDER_ERROR_CODES.INCOMPATIBLE_LOGGED_USER_DATA
          ) {
            openModal({
              component: SwanIncompatibleUserModal,
              props: {
                user: bankProviderLoginError.metadata,
              },
            });
            return;
          }

          if (
            bankProviderLoginError.errorCode ===
            BANK_PROVIDER_ERROR_CODES.USER_NOT_VERIFIED
          ) {
            openModal({
              component: NotVerifiedUserModal,
            });
            return;
          }

          showError({
            message: t(
              `bankProvider:errors.${bankProviderLoginError.errorCode}.title`,
            ),
            description: t(
              `bankProvider:errors.${bankProviderLoginError.errorCode}.description`,
            ),
          });
        }
      },
      onError: (error) => {
        return handleHttpErrors(
          { error, t, notifyFn: showError },
          showValidationErrorNotification('bankProvider:errors'),
        );
      },
    }),
  );

  if (
    httpError.response?.data.errorCode ===
    BANK_PROVIDER_ERROR_CODES.BANK_PROVIDER_REQUIRED_LOGIN
  ) {
    showWarning({
      message: t(
        `bankProvider:warnings.${BANK_PROVIDER_ERROR_CODES.BANK_PROVIDER_REQUIRED_LOGIN}.title`,
      ),
      description: t(
        `bankProvider:warnings.${BANK_PROVIDER_ERROR_CODES.BANK_PROVIDER_REQUIRED_LOGIN}.description`,
      ),
    });
    bankProviderLogin({ redirectUri: window.location.href });
    return true;
  }
  return false;
};
