import {
  ToastPropsWithOptionalDistinctId,
  useToastStore,
} from '../store/toast.store.ts';
import { ToastVariant } from '@tundr/theme';

export const useToast = () => {
  const store = useToastStore();

  const showVariant =
    (variant: ToastVariant) =>
    (payload: Omit<ToastPropsWithOptionalDistinctId, 'variant'>) => {
      store.setItem({
        variant,
        ...payload,
      });
    };

  const showError = showVariant(ToastVariant.ERROR);
  const showSuccess = showVariant(ToastVariant.SUCCESS);
  const showInfo = showVariant(ToastVariant.INFO);
  const showWarning = showVariant(ToastVariant.WARNING);

  return { show: store.setItem, showError, showSuccess, showInfo, showWarning };
};
