import { useServiceAgreementsStore } from '../store/service-agreements.store';
export const useServiceAgreements = () => {
  const {
    privacyAcceptance,
    termsAcceptance,
    setPrivacyAcceptance,
    setTermsAcceptance,
  } = useServiceAgreementsStore();
  return {
    privacyAcceptance,
    termsAcceptance,
    setPrivacyAcceptance,
    setTermsAcceptance,
  };
};
