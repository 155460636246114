import { WelfarePlan, useAuthStore } from '@tundr/auth';
import { fringeThresholdsRoutesDictionary } from './fringe-thresholds-routes.dictionary';

export const fringeThresholdsRoutesConfig = {
  ...fringeThresholdsRoutesDictionary.fringeThresholds.root,
  redirect: {
    name: fringeThresholdsRoutesDictionary.fringeThresholds.list.name,
  },
  beforeEnter: () => {
    const authStore = useAuthStore();
    return authStore.currentWelfarePlan === WelfarePlan.COMPLETE;
  },
  children: [
    {
      ...fringeThresholdsRoutesDictionary.fringeThresholds.list,
      component: () => import('../pages/FringeThresholds/FringeThresholds.vue'),
    },
  ],
};
