export enum ICONS {
  CARD_REFRESH = 'card-refresh',
  CARD_NEW = 'card-new',
  CARD_CONFLICT = 'card-conflict',
  GROUPS = 'groups',
  FAQ = 'faq',
  HEADQUARTERS = 'headquarters',
  EYE_HIDDEN = 'eye_hidden',
  EYE_VISIBLE = 'eye_visible',
  CHANGE = 'Change',
  SETTINGS = 'settings',
  ADD_USER = 'add-user',
  ADD = 'add',
  ARROW_SMALL = 'arrow-small',
  ARROW_CLOSE = 'arrow-close',
  BENEFIT = 'Benefit',
  BLOCK_USER = 'block-user',
  BRANCH = 'branch',
  CALENDAR = 'calendar',
  CARD_ADD_NEW = 'card-add-new',
  CARD_BLOCK = 'card---block',
  CARD_RECHARGE = 'card---recharge',
  CARD_ACTIVE = 'card-active',
  CARD_ALLERT = 'card-allert',
  CARD = 'card',
  CARE = 'Care',
  CLOSE = 'close',
  CLOTHING = 'clothing',
  CLOUD_UPLOAD = 'cloud-upload',
  CO2 = 'co2',
  COLOSSEUM = 'colosseum',
  COPYRIGHT = 'copyright',
  DASHBOARD = 'dashboard',
  DELETE_FILE = 'delete-file',
  DISCOUNT = 'discount',
  DOCUMENT_TRANSFER = 'document-transfer',
  DOUBLE_ARROW = 'double-arrow',
  DOWNLOAD_ARROW = 'download-arrow',
  EDIT = 'edit',
  EMAIL = 'email',
  EURO = 'euro',
  FILTER = 'filter',
  GROUP = 'group',
  HOLIDAYS = 'holidays',
  HOME = 'home',
  INFO = 'info',
  KEBAB_MENU = 'kebab-menu',
  LEARNING = 'learning',
  LIST = 'list',
  LOADING = 'Loading',
  LOCATION = 'location',
  LOCK_POINT = 'lock---point',
  LOCK_X = 'lock---x',
  LOCK_PLAN = 'lock-plan',
  LOCK = 'lock',
  LOGOUT = 'logout',
  MAKEUP = 'makeup',
  MAP = 'map',
  PARTY = 'Party',
  PHONE_1 = 'phone-1',
  PHONE_AND_CARD = 'phone-and-card',
  PHONE = 'phone',
  PHOTO_OFF = 'photo-off',
  PHOTO = 'photo',
  PLAN = 'plan',
  REFUND_SPLIT = 'Refund-split',
  REFUND = 'Refund',
  REFUNDS = 'refunds',
  REMOVE_USER = 'remove-user',
  SEARCH = 'search',
  SETTINGS1 = 'settings1',
  SHOP = 'shop',
  SOFA = 'sofa',
  SORT_BY = 'sort-by',
  SPORT_1 = 'sport-1',
  SPORT = 'sport',
  STORE_MOVE = 'store-move',
  STREAMING = 'streaming',
  SUCCESS = 'Success',
  SUPPORT = 'Support',
  TECH = 'tech',
  TIME = 'time',
  TRANSFER_BANK = 'transfer-bank',
  TRANSFER_VOUCHER = 'transfer-voucher',
  TRAVEL_1 = 'travel-1',
  TRAVEL = 'travel',
  TUNDR_EAT = 'Tundr-EAT',
  TUNDR_GIFT = 'Tundr-Gift',
  TUNDR_MOVE = 'Tundr-Move',
  UNSUCCESS = 'unsuccess',
  USER_HR = 'user-hr',
  USER = 'user',
  VOUCHER = 'voucher',
  WALLET_REFUND = 'Wallet-refund',
  WALLET = 'wallet',
  WARNING_HARD = 'warning-hard',
  WARNING_SOFT = 'warning-soft',
  WARNING = 'warning',
  ALIMENTAZIONE_VEICOLI = 'alimentazione-veicoli',
  MOBILITA = 'mobilita',
  MULTIUSO = 'multiuso',
  CONFIRM = 'confirm',
  CHECK = 'check',
}
