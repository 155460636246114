import { defineStore } from 'pinia';
import { BreadCrumbItemProps } from '../components/BreadCrumb';
import { ref } from 'vue';

type BreadcrumbState = {
  items: BreadCrumbItemProps[];
};

export const useBreadcrumbStore = defineStore('breadcrumb', () => {
  const items = ref<BreadcrumbState['items']>([]);
  const setItems = (newValue: BreadCrumbItemProps[]) => {
    items.value = [...newValue];
  };
  const reset = () => {
    items.value = [];
  };

  return { items, setItems, reset };
});
