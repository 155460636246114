<script setup lang="ts">
  import { ComponentCommonTypes } from '../utils/commonTypes';
  import { linkVariants } from './Link.css';
  import { LinkTarget } from './Link.types';
  import { computed } from 'vue';
  import { LinkVariant } from '@tundr/theme';
  import { sanitizeUrl } from '@braintree/sanitize-url';

  export type LinkProps = ComponentCommonTypes & {
    target?: LinkTarget;
    variant?: LinkVariant;
    href: string;
  };

  const props = withDefaults(defineProps<LinkProps>(), {
    target: LinkTarget.BLANK,
    variant: LinkVariant.primary,
  });

  const sanitizedUrl = computed(() => props.href && sanitizeUrl(props.href));

  const linkClasses = computed(() => [linkVariants[props.variant]]);
</script>

<template>
  <a
    role="link"
    :id="id"
    :data-test-id="testId"
    :title="title"
    :class="linkClasses"
    :href="sanitizedUrl"
    :target="target"
    ><slot
  /></a>
</template>
