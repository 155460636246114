import { ModalConfig } from './../components/Modal/Modal.types';
import { ModalStoreProps, useModalStore } from '../store/modal.store';
import { ConfirmModal } from '../components';

type ConfirmModalPayload = {
  title: string;
  message?: string;
  disclaimerTitle?: string;
  disclaimerDescription?: string;
  config?: ModalConfig;
  image?: string;
  labels?: {
    confirm?: string;
    cancel?: string;
  };
  events: { onCancel?: () => void; onConfirm: () => void };
};

export const useModal = () => {
  const modalStore = useModalStore();

  return {
    props: modalStore.modalState.props,
    events: modalStore.modalState.events,
    config: modalStore.modalState.config,
    labels: modalStore.modalState.labels,
    openConfirmModal: (payload: ConfirmModalPayload) => {
      modalStore.openModal({
        component: ConfirmModal,
        config: payload.config || {
          blocking: true,
        },
        props: {
          title: payload.title,
          message: payload.message,
          image: payload.image,
          disclaimerTitle: payload.disclaimerTitle,
          disclaimerDescription: payload.disclaimerDescription,
        },
        labels: {
          confirm: payload.labels?.confirm,
          cancel: payload.labels?.cancel,
        },
        events: payload.events,
      });
    },
    openModal: (payload: ModalStoreProps) => {
      modalStore.openModal(payload);
    },
    closeModal: () => {
      modalStore.closeModal();
    },
  };
};
