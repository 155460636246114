import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export const SERVICE_AGREEMENTS_STORE_NAME = 'serviceAgreements';

export const useServiceAgreementsStore = defineStore(
  SERVICE_AGREEMENTS_STORE_NAME,
  () => {
    const privacyAcceptance = useLocalStorage<boolean>('privacyPolicy', false);
    const termsAcceptance = useLocalStorage<boolean>(
      'termsAndConditions',
      false,
    );

    const setPrivacyAcceptance = (value: boolean) => {
      privacyAcceptance.value = value;
    };

    const setTermsAcceptance = (value: boolean) => {
      termsAcceptance.value = value;
    };

    return {
      privacyAcceptance,
      termsAcceptance,
      setPrivacyAcceptance,
      setTermsAcceptance,
    };
  },
);
