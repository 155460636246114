import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const officeRoutesDictionary = moduleRouteDictionaryFactory(
  'offices',
  'offices',
  {
    list: 'list',
    create: 'create',
    update: 'update/:id',
  },
);
