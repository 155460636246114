import { HttpInstance } from '../types.ts';
import { AxiosInterceptorManager } from 'axios';
import { useHttpInstance } from './use-http-instance.ts';

type HttpConfigureFn = (http: HttpInstance) => unknown;

function useConfigureHttp(): (configureFn: HttpConfigureFn) => void {
  const httpInstance = useHttpInstance();
  return (configureFn: HttpConfigureFn) => configureFn(httpInstance);
}

export type HttpRequestHeaderOptions = Record<string, string>;

export function useHttpRequestHeaders() {
  const configureHttp = useConfigureHttp();

  const setHeaders = (headers: HttpRequestHeaderOptions) => {
    configureHttp((http: HttpInstance) => {
      http.defaults.headers.common = {
        ...http.defaults.headers.common,
        ...headers,
      };
    });
  };

  const deleteHeader = (headerKey: string) => {
    configureHttp((http: HttpInstance) => {
      delete http.defaults.headers.common[headerKey];
    });
  };
  return {
    setHeaders,
    deleteHeader,
  };
}

export type HttpResponseInterceptorFn<V> = Parameters<
  AxiosInterceptorManager<V>['use']
>[0];

export type HttpResponseErrorInterceptorFn<V> = Parameters<
  AxiosInterceptorManager<V>['use']
>[1];

export function useHttpResponseInterceptor<T>(
  interceptorFn: HttpResponseInterceptorFn<T>,
): void {
  const configureHttp = useConfigureHttp();

  configureHttp((http) => {
    (http.interceptors.response as AxiosInterceptorManager<T>).use(
      interceptorFn,
    );
  });
}

export function useHttpResponseErrorInterceptor<T>(
  interceptorFn: HttpResponseErrorInterceptorFn<T>,
): void {
  const configureHttp = useConfigureHttp();

  configureHttp((http) => {
    (http.interceptors.response as AxiosInterceptorManager<T>).use(
      null,
      interceptorFn,
    );
  });
}
