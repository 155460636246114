<script setup lang="ts">
  import VueDatePicker, { DatePickerInstance } from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import { TextInput } from '../TextInput';
  import { InputWidthVariant } from '../InputWrapper';
  import { inputWidthClass } from '../InputWrapper/InputWrapper.css.ts';
  import { computed, ref, useTemplateRef } from 'vue';
  import {
    customTimePickerMenuClass,
    timePickerBaseClass,
  } from './TimePicker.css.ts';
  import { DatePickerProps } from '@tundr/ui-kit';
  import { FieldMeta } from 'vee-validate';
  import { format } from 'date-fns';

  const props = withDefaults(
    defineProps<
      DatePickerProps & { modelValue: string; meta?: FieldMeta<string> }
    >(),
    {
      disableTimePicker: true,
      is24: true,
    },
  );

  const datepicker = useTemplateRef<DatePickerInstance>('input');
  const internalModelRef = ref(props.modelValue);

  const handleInternalModelChange = (time: Date | null) => {
    if (!time) {
      internalModelRef.value = '';
      return;
    }
    internalModelRef.value = format(time, 'HH:mm');
  };

  defineOptions({
    inheritAttrs: false,
  });

  const timePickerClasses = computed<string[]>(() => [
    timePickerBaseClass,
    inputWidthClass[props.width ?? InputWidthVariant.AUTO],
  ]);

  const emit = defineEmits<{
    'update:modelValue': [string];
    blur: [Event | undefined];
  }>();

  const onBlur = (ev?: Event) => {
    emit('blur', ev);
  };

  const onConfirmInput = () => {
    emit('update:modelValue', internalModelRef.value);
    datepicker.value!.closeMenu();
  };

  const onFocusOut = () => {
    emit('update:modelValue', internalModelRef.value);
    emit('blur', undefined);
  };

  const handleInput = (time: string) => {
    if (/^(?:[01]\d|2[0-3]):[0-5]\d$/.test(time)) {
      datepicker.value!.selectDate();
      emit('update:modelValue', time);
    }
  };
</script>

<template>
  <div :class="timePickerClasses">
    <VueDatePicker
      ref="datepicker"
      :id="id"
      :name="name"
      :menu-class-name="customTimePickerMenuClass"
      :enable-time-picker="!disableTimePicker"
      :model-value="modelValue"
      :start-time="startTime"
      :clearable="false"
      :is24="is24 || true"
      :disabled="disabled || loading || readonly"
      :min-time="minTime"
      :max-time="maxTime"
      :star-time="startTime"
      auto-apply
      text-input
      model-type="HH:mm"
      time-picker
      @internal-model-change="handleInternalModelChange"
      @update:model-value="emit('update:modelValue', $event)"
      @blur="onBlur"
    >
      <template #dp-input="inputProps">
        <TextInput
          :test-id="testId"
          :disabled="disabled || loading"
          :loading="loading"
          :readonly="readonly"
          :label="label"
          :placeholder="placeholder"
          :error="error"
          :width="width"
          :name="name"
          @onEnter="onConfirmInput"
          @onTab="onConfirmInput"
          @blur="onBlur"
          @focusout="onFocusOut"
          @update:model-value="handleInput"
          :model-value="inputProps.value"
          v-bind="inputProps"
        />
      </template>
    </VueDatePicker>
  </div>
</template>
