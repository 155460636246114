<script lang="ts" setup>
  import { BreadCrumb } from '@tundr/breadcrumb';
  import { Notification, useNotification } from '@tundr/notification';
  import { computed } from 'vue';
  import {
    containerClass,
    contentClass,
    contentWithNotificationClass,
    contentWrapperClass,
    headerWrapperClass,
  } from './PageLayout.css';
  import { ScrollTopButton } from './ScrollTopButton';

  const notification = useNotification();

  const hasNotifications = computed(() => {
    return notification.getLastNotification.value;
  });

  withDefaults(
    defineProps<{
      disableScrollToTop?: boolean;
    }>(),
    {
      disableScrollToTop: false,
    },
  );

  const contentWrapperClasses = computed(() => {
    return [
      contentWrapperClass,
      { [contentWithNotificationClass]: hasNotifications.value },
    ];
  });
</script>

<template>
  <div :class="containerClass">
    <slot name="sidebar" />
    <div :class="contentWrapperClasses">
      <Notification />
      <slot name="header">
        <div :class="headerWrapperClass">
          <div>
            <slot name="headerLeft">
              <BreadCrumb />
            </slot>
          </div>
          <div>
            <slot name="headerRight" />
          </div>
        </div>
        <div :class="contentClass">
          <slot />
        </div>
      </slot>
    </div>
    <ScrollTopButton v-if="!disableScrollToTop" />
  </div>
</template>
