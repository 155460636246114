import { isMatch, formatISO } from 'date-fns';

export function toISODateOnlyString(date: Date): string {
  return formatISO(date, { representation: 'date' });
}

export const isMatchYears = (date: string, pattern: string) => {
  const yearsLen: number = pattern.split('y').length - 1;
  if (yearsLen === 0) return true;

  const yearsIndex = pattern.indexOf('y');
  const afterYears = date.slice(yearsIndex);
  const yearsMatch = afterYears.match(/^.\d+/);
  if (yearsMatch && yearsMatch[0].match(/\d+/)![0].length === yearsLen)
    return true;
  return false;
};

export const isDateMatch = (date: string, pattern: string) => {
  return isMatch(date, pattern) && isMatchYears(date, pattern);
};
