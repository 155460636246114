import { runInsideAppContext } from '@tundr/app-context';
import { HttpStatusCode, useHttpInstance } from '@tundr/http';
import { useAuthStore } from './store';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { useRouter } from 'vue-router';

export const onInterceptUnauthorized = async (error: AxiosError) => {
  const authStore = useAuthStore();
  const router = runInsideAppContext(useRouter);

  const closeSession = () => {
    authStore.logout();
    router.replace('/');
    return Promise.reject(error);
  };

  const http = runInsideAppContext(useHttpInstance);

  // Detect that the user is unauthorized from the status code
  const status = error.response?.status;

  if (
    status !== HttpStatusCode.Forbidden &&
    status !== HttpStatusCode.Unauthorized
  ) {
    return Promise.reject(error);
  }
  // Save the original request config
  const originalRequestConfig: InternalAxiosRequestConfig & {
    _retry?: boolean;
  } = error.config!;

  // Check if already tried to refresh the token or the original request
  if (originalRequestConfig._retry) {
    await closeSession();
  }
  try {
    await http.post('auth/refresh');
  } catch (err) {
    await closeSession();
  }
  originalRequestConfig._retry = true;
  return http.request(originalRequestConfig);
};
