<script lang="ts" setup>
  import { useAuthStore } from '@tundr/auth';
  import { useLocale } from '@tundr/i18n';
  import { useModal } from '@tundr/modal';
  import { textEllipsisClass } from '@tundr/theme';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import {
    FontIcon,
    Menu,
    MenuItem,
    PlacementOptions,
    Tooltip,
  } from '@tundr/ui-kit';
  import { computed, defineAsyncComponent } from 'vue';

  import {
    companyActionsClass,
    companyIconCollapsedClass,
    companyNameClass,
    menuTitleClass,
    sidebarCompanyRowClass,
    sidebarCompanyRowExpandedClass,
  } from './SidebarCompanyRow.css';

  const props = defineProps<{
    collapsed: boolean;
  }>();

  const { t } = useLocale('common');
  const { openModal } = useModal();

  const authStore = useAuthStore();

  const SwitchCompanyModal = defineAsyncComponent(
    () => import('../SwitchCompanyModal/SwitchCompanyModal.vue'),
  );

  const AddNewCompanyEmailCodeModal = defineAsyncComponent(
    () =>
      import(
        '../../../../modules/app/user/components/AddNewCompanyEmailCodeModal/AddNewCompanyEmailCodeModal.vue'
      ),
  );

  const onSwitchCompany = () => {
    openModal({
      component: SwitchCompanyModal,
      config: {
        blocking: false,
      },
    });
  };

  const onAddNewCompany = () => {
    openModal({
      component: AddNewCompanyEmailCodeModal,
      config: {
        blocking: true,
      },
    });
  };

  const triggerIcon = computed(() => {
    if (props.collapsed) {
      return UNICONS_ICONS.BUILDING;
    }
    return UNICONS_ICONS.ANGLE_DOWN;
  });

  const sidebarCompanyRowClasses = computed(() => [
    sidebarCompanyRowClass,
    {
      [sidebarCompanyRowExpandedClass]: !props.collapsed,
    },
  ]);

  const selectedCompanyName = computed(() => authStore.selectedCompany?.name);
</script>

<template>
  <div :class="sidebarCompanyRowClasses">
    <template v-if="!collapsed">
      <div :class="companyNameClass">
        <FontIcon v-if="!collapsed" :iconName="UNICONS_ICONS.BUILDING" />
        <span v-if="!collapsed" :class="textEllipsisClass">{{
          selectedCompanyName
        }}</span>
      </div>
      <div :class="companyActionsClass">
        <Tooltip :label="t('profile_menu.actions.switch_company.label')">
          <FontIcon
            clickable
            boxed
            v-if="authStore.userCompanies && authStore.userCompanies.length > 1"
            @click="onSwitchCompany"
            :iconName="UNICONS_ICONS.ARROWS_H"
          />
        </Tooltip>
        <Tooltip :label="t('profile_menu.actions.add_company.label')">
          <FontIcon
            clickable
            boxed
            @click="onAddNewCompany"
            :iconName="UNICONS_ICONS.PLUS"
          />
        </Tooltip>
      </div>
    </template>
    <Menu v-else :placement="PlacementOptions.BOTTOM_END">
      <template #trigger>
        <FontIcon
          clickable
          :title="collapsed ? `${selectedCompanyName}` : ''"
          :icon-name="triggerIcon"
          :class="collapsed && companyIconCollapsedClass"
        />
      </template>
      <div>
        <div :class="menuTitleClass">
          {{ selectedCompanyName }}
        </div>
        <MenuItem
          :icon="UNICONS_ICONS.ARROWS_H"
          test-id="menu-item-switch-company"
          @click="onSwitchCompany"
          :label="t('profile_menu.actions.switch_company.label')"
        />
        <MenuItem
          :icon="UNICONS_ICONS.PLUS"
          test-id="menu-item-add-company"
          @click="onAddNewCompany"
          :label="t('profile_menu.actions.add_company.label')"
        />
      </div>
    </Menu>
  </div>
</template>
