import { WelfarePlan, useAuthStore } from '@tundr/auth';
import { flexCreditsRoutesDictionary } from './flex-credits-routes.dictionary';

export const flexCreditsRoutesConfig = {
  ...flexCreditsRoutesDictionary.flexCredits.root,
  redirect: { name: flexCreditsRoutesDictionary.flexCredits.list.name },
  beforeEnter: () => {
    const authStore = useAuthStore();
    return authStore.currentWelfarePlan === WelfarePlan.COMPLETE;
  },
  children: [
    {
      ...flexCreditsRoutesDictionary.flexCredits.list,
      component: () => import('../pages/FlexCreditsList/FlexCreditsList.vue'),
    },
    {
      ...flexCreditsRoutesDictionary.flexCredits.assign,
      component: () =>
        import('../pages/FlexCreditsAssignment/FlexCreditsAssignmentPage.vue'),
    },
  ],
};
