<script setup lang="ts">
  import { SkeletonVariants } from './Skeleton.types';
  import { skeletonClass, skeletonVariantClass } from './Skeleton.css';
  import { computed } from 'vue';
  import { ComponentCommonTypes } from '../utils/commonTypes.ts';

  export type SkeletonProps = ComponentCommonTypes & {
    variant?: SkeletonVariants;
    width?: number;
    height?: number;
  };

  const props = withDefaults(defineProps<SkeletonProps>(), {
    variant: SkeletonVariants.CIRCLE,
    width: 80,
    height: 80,
  });

  const SkeletonClasses = computed(() => {
    return `${skeletonClass} ${skeletonVariantClass[props.variant]}`;
  });
</script>

<template>
  <div
    :class="SkeletonClasses"
    :style="{ width: `${props.width}px`, height: `${props.height}px` }"
  />
</template>
