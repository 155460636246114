<script setup lang="ts">
  import { ICONS } from '@tundr/theme-assets/font-icon/icons';
  import { menuItemClass, menuItemIconClass } from './MenuItem.css';
  import Icon from '../../Icon/Icon.vue';
  import FontIcon from '../../FontIcon/FontIcon.vue';
  import { ComponentCommonTypes } from '../../utils/commonTypes.ts';
  import { computed } from 'vue';
  import { disabledClass } from '@tundr/theme';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';

  export type MenuProps = ComponentCommonTypes & {
    icon?: ICONS | UNICONS_ICONS;
    isFontIcon?: boolean;
    label: string;
    disabled?: boolean;
  };
  const props = defineProps<MenuProps>();

  const itemClass = computed(() => {
    return [
      menuItemClass,
      {
        [disabledClass]: props.disabled,
      },
    ];
  });
</script>

<template>
  <div :class="itemClass" :data-test-id="testId" :id="id">
    <Icon
      v-if="icon && !isFontIcon"
      :icon-name="icon as ICONS"
      :class="menuItemIconClass"
    />
    <FontIcon
      v-if="icon && isFontIcon"
      :iconName="icon as UNICONS_ICONS"
      :class="menuItemIconClass"
    />
    <div>{{ label }}</div>
  </div>
</template>
