import { moduleRouteDictionaryFactory } from '@tundr/routing';

export const creditsRoutesDictionary = moduleRouteDictionaryFactory(
  'credits',
  'credits',
  {
    overview: 'overview',
    assign: 'assign',
    detail: 'detail/:id/:status',
  },
);
