<script setup lang="ts">
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import FontIcon from '../FontIcon/FontIcon.vue';
  import { ComponentCommonTypes } from '../utils/commonTypes.ts';
  import {
    disclaimerClass,
    disclaimerIconClass,
    disclaimerContentClass,
    disclaimerTitleClass,
    disclaimerFullWidthClass,
    disclaimerDescriptionClass,
  } from './Disclaimer.css';
  import { computed } from 'vue';

  export type DisclaimerProps = ComponentCommonTypes & {
    title?: string;
    description?: string;
    fullWidth?: boolean;
  };

  const props = defineProps<DisclaimerProps>();

  const disclaimerClasses = computed(() => ({
    [disclaimerClass]: true,
    [disclaimerFullWidthClass]: props.fullWidth,
  }));
</script>

<template>
  <div :class="disclaimerClasses">
    <div :class="disclaimerIconClass">
      <FontIcon :iconName="UNICONS_ICONS.INFO_CIRCLE" />
    </div>
    <div :class="disclaimerContentClass">
      <div :class="disclaimerTitleClass">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div :class="disclaimerDescriptionClass">
        <slot name="description">
          {{ description }}
        </slot>
      </div>
    </div>
  </div>
</template>
